<template>
    <div>
        <template v-if="!wapShow">
            <div class="detail-content">
                <div class="navigation-box">
                    <router-link to="/">首页</router-link><span style="margin: 0 3px">></span>
                    <router-link to="/message">资讯与动态</router-link><span style="margin: 0 3px">></span>
                    {{obj.name}}
                </div>
                <div v-html="obj.content"></div>
            </div>
        </template>

        <template v-else>
            <messageDetail-wap></messageDetail-wap>
        </template>
    </div>
</template>

<script>
    import eventBus from "@/assets/js/eventBus";
    import messageDetailWap from "./wap/Details"

    export default {
        name: "messageDetail",
        components: {messageDetailWap},
        data() {
            return {
                obj:{content:""},
                list:[],
                wapShow:false
            }
        },
        mounted() {
            this.list = require('@/assets/js/data.js').default;
            this.list.forEach((item)=>{
                if(item.id == this.$route.query.id){
                    this.obj=item;
                    document.getElementsByTagName("title")[0].innerText = item.name+'_重庆敏恒科技有限公司';
                }
            });

            //切换手机端
            if(this.$route.query) this.wapShow = this.$route.query.wapShow;
            eventBus.$on("wapShow",(data)=>{
                this.wapShow= data;
            });
            console.log("this.wapShow:",this.wapShow)
        }
    }
</script>

<style scoped>
    .detail-content {
        text-align: left;
        margin-top: 30px;
        font-size: 16px;
    }
</style>
