<template>
  <div>
    <template v-if="!wapShow">
      <div class="about">
        <div class="navigation-box">
          <router-link to="/">首页</router-link>
          <span style="margin: 0 3px">></span>
          {{ selectIndex == 1 ? '公司介绍' : selectIndex == 2 ? '职业机会' : selectIndex == 3 ? '联系我们' : '' }}
        </div>

        <div class="about-box">
          <div class="Technology-header">
            <div class="left">
              <span style="padding-left: 21px;background:none;cursor: auto;font-family: PingFangSC-Medium, PingFang SC">关于我们</span>
              <router-link to="/about?index=1">
          <span :class="{ select: selectIndex == 1 }"
          >公司介绍</span
          >
              </router-link>
              <router-link to="/about?index=2">
          <span
              :class="{ select: selectIndex == 2 }"
              style="position: relative"
          >职业机会
        </span>
              </router-link>
              <router-link to="/about?index=3">
                <span :class="{ select: selectIndex == 3 }">联系我们</span>
              </router-link>
            </div>
            <div class="right">
              <template v-if="selectIndex == 1">
                <img class="img1" src="@/assets/image/about/1.png"/>
              </template>

              <template v-if="selectIndex == 2">
                <img class="img1" src="@/assets/image/about/4.png"/>
              </template>

              <template v-if="selectIndex == 3">
                <img class="img1" src="@/assets/image/about/5.png"/>
              </template>
            </div>
          </div>

          <template v-if="selectIndex == 1">
            <div class="about-centent">
              <div class="content1">
                <div class="about-title">COMPANY INTRODUCTION</div>
                <div class="about-title-zh">公司简介</div>

                <div style="margin-top: 50px">
                  <img class="i1" src="@/assets/image/about/2.png"/>
                  <div class="p2">
                    <div class="text1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重庆敏恒科技有限公司是一家为化工、能源、材料和制药等领域的客户提供化工技术和化工模块化装备解决方案服务的科技型创新企业。
                      <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;敏恒科技依据绿色化工理念，综合应用功能整合、连续流等技术，使生产流程密集化、设备微型化、生产过程智能化、资源利用最大化。帮助合作伙伴提升化工生产效率和产品质量、减少安全事故、降低劳动强度，为客户创造持久竞争优势。
                      <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;敏恒科技拥有专业和专注的团队，在升华结晶、熔融结晶等分离提纯领域拥有多项创新性成果；在电石渣制备高纯碳酸钙、高盐废水资源化利用等三废资源化领域成功实施多个工程；完整掌握锂电池添加剂碳酸乙烯酯、硫酸乙烯酯、双氟磺酰亚胺锂等产品的领先成熟大生产工艺技术。
                      <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      敏恒科技坚持开放合作、持续创新，致力于将先进的化工技术和化工单元模块化装备带给客户，与客户一起为实现操作舒适、环境友好、自动智能运行的化工生产而努力。
                    </div>
                  </div>
                </div>
              </div>

              <div class="content1" style="margin-top: 30px">
                <div class="about-title">CORPORATE CULTURE</div>
                <div class="about-title-zh">公司文化</div>

                <img class="i2" src="@/assets/image/about/3.png">
              </div>
              <div class="content1" style="margin-top: 30px">
                <div class="about-title">COMPANY INTRODUCTION</div>
                <div class="about-title-zh">公司荣誉</div>

                <img class="i2" src="@/assets/image/about/introduction.jpg">
              </div>

            </div>
          </template>
          <template v-if="selectIndex == 2">
            <div class="about-centent">
              <div class="content1">
                <div class="about-title">JOIN US</div>
                <div class="about-title-zh">职业机会</div>

                <div class="c2-p1">
                  人才是公司最宝贵的财富！公司致力于在内部打造一个尊重知识，尊重人才的和谐工作氛围。在敏恒科技，您可以与许多优秀的人才合作，他们致力于创新，他们致力于为化工生产提供更先进的突破性技术。如果你喜欢一家务实，创新的公司，喜欢高效率的工作环境，喜欢多元化的发展，那么，我相信您会喜欢敏恒科技的工作，加入我们，您将拥有众多增长知识，提高技能的机会，您可根据自身的特长和兴趣来选择技术发展路线或管理发展路线。
                </div>

                <div class="c2-box">
                  <div v-for="item in list" class="c2-boxs" @click="item.judge=!item.judge">
                    <div :class="{'c2-li-select':item.judge}"
                         :style="{'border':item.judges?'1px solid #0079FF':'','background-color':item.judges?'#E8F3FF':''}"
                         class="c2-li" @mousemove="item.judges=true"
                         @mouseout="item.judges=false">
                      <div class="flex-center">
                        <i :class="item.judge||item.judges?'icon-zhiwei-copy':'icon-zhiwei'" class="iconfont"
                           style="font-size: 22px;margin-right: 20px"></i>
                        <span :style="{'color':(item.judge||item.judges?'rgba(0, 121, 255, 1)':'')}"
                              class="t1">{{ item.name }}</span>
                      </div>
                      <div class="flex-center" style="font-size: 16px" @click="item.judge=!item.judge">
                        <i class="iconfont icon-a-zhiweiweizhi"></i>
                        <span class="t2">{{ item.place }}</span>
                        <template v-if="item.judges">
                          <i :class="item.judge?'icon-xia':'icon-you'" class="iconfont "
                             style="font-size: 32px;cursor: pointer;color: #0079FF" @click="item.judge=!item.judge"></i>
                        </template>
                        <template v-else>
                          <i :class="item.judge?'icon-a-zhiweizhankai':'icon-a-zhiweimoren'" class="iconfont "
                             style="font-size: 32px;cursor: pointer" @click="item.judge=!item.judge"></i>
                        </template>
                      </div>
                      <div v-show="item.judge" class="borderb"></div>
                    </div>

                    <div v-show="item.judge" class="cons" v-html="item.content">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="selectIndex == 3">
            <div class="about-centent">
              <div class="content1">
                <div class="about-title">CONTACT US</div>
                <div class="about-title-zh">联系我们</div>

                <div class="about-img1">
                  <div class="right">
                    <div class="flex-center" style="margin-top: 53px"><i
                        class="iconfont icon-a-lianxiwomendizhi flex-center"></i>地址：重庆市巴南区木洞镇天益路9号重庆国际生物城E3栋2单元
                    </div>
                    <!--                    <div style="margin-top: 44px" class="flex-center"><i class="iconfont icon-a-lianxiwomendianhua flex-center"></i>咨询热线：023-67665596</div>-->
                    <!--                    <div style="margin-top: 8px;margin-left: 44px">技术咨询：185 2345 4096 李军</div>-->
                    <div style="margin-top: 10px">
                      <i class="iconfont icon-a-lianxiwomendianhua flex-center"></i>
                      <div>
                        <div style="padding-top: 6px;margin-left: 42px">商务交流：185 8087 5987 张先生</div>
                        <div style="margin-left: 113px;margin-top: 10px">023-67665596</div>
                      </div>
                    </div>


                    <div class="flex-center" style="margin-top: 10px"><i
                        class="iconfont icon-a-lianxiwomenyouxiang flex-center"></i>邮箱：service@mhkjcq.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>

    <template v-else>
      <about-wap></about-wap>
    </template>

  </div>
</template>

<script>
import eventBus from "@/assets/js/eventBus";
import aboutWap from "./wap/index"


export default {
  name: "about",
  components: {aboutWap},
  data() {
    return {
      selectIndex: 1,
      list: [
        {
          id: 1,
          name: "化工设备设计工程师（12K-24K/月）",
          place: "重庆",
          judge: false,
          judges: false,
          content: '<p><strong>一、工作职责</strong></p>\n' +
              '    <p><span>1、根据工艺要求，进行产品的结构设计或系统工程设计，做好图纸设计、制图、校对、组织评审、下发材料清单等工作；</span></p>\n' +
              '    <p><span>2、协助生产部的设备装配，配合完成产品制作和外加工中与机械、结构件相关的技术支持工作；协助项目部工艺设计招标文件的结构布局设计；配合品管部进行所设计的机械、结构件的检验工作；&nbsp;</span></p>\n' +
              '    <p><strong>二、任职条件</strong></p>\n' +
              '    <p><span>1、本科以上学历，3-5年工作经验。</span></p>\n' +
              '    <p><span>2、熟练使用2D/3D软件</span></p>\n' +
              '    <p><span>3、有化工动静设备设计、化工管道等相关的设计经验优先。</span><span>。</span></p>\n' +
              '    <p><span>&nbsp;</span></p>\n' +
              '    <p><span>工作地点：</span><span>重庆巴南区木洞镇国际生物城</span></p>\n' +
              '    <p><span style="margin-left: 80px">重庆市江北区五里店科技金融中心人保财险大厦</span></p>\n' +
              '    <p><span>待遇：面议</span></p>\n' +
              '    <p><span>简历投递邮箱：</span><u>swengin@163.com</u></p>\n' +
              '    <p><span>联系电话：13527548045，匡经理</span></p>\n' +
              '    <p><br></p>'
        },
        {
          id: 2,
          name: "合成研发工程师（12K-24K/月）",
          place: "重庆",
          judge: false,
          judges: false,
          content: '<p><strong>一、岗位职责</strong></p>\n' +
              '    <p><span>1、熟练地完成项目实验化学反应，并对结果做出较全面的分析判断，完成实验报告；</span></p>\n' +
              '    <p><span>2、能够依据文献完成化合物合成路线设计,路线筛选、工艺优化并对合成反应中杂质进行合理分析和结构推断；</span></p>\n' +
              '    <p><span>3、独立解决实验中出现的简单问题，清晰完整地完成实验记录，实验报告书；</span></p>\n' +
              '    <p><span>4、可以熟练进行英文参考文献和专利调研的查阅。</span></p>\n' +
              '    <p><strong>二、任职条件</strong></p>\n' +
              '    <p><span>1、有机化学、药物化学、应用化学等相关专业，正规院校硕士学历2年以上合成研发工作经历或者211、985本科以上担任过项目负责人或在有机合成方面有5年以上且丰富的经验；</span></p>\n' +
              '    <p><span>2、具有扎实的专业知识和较强的解决问题的能力，能熟练进行文献检索,阅读英文技术资料及撰写项目研究方案、实验报告；</span></p>\n' +
              '    <p><span>3、有较强责任心，积极热情，开拓进取，具有良好的创新意识及团队合作精神，对有机合成有浓厚兴趣。</span><span>。</span></p>\n' +
              '    <p><span>&nbsp;</span></p>\n' +
              '    <p><span>工作地点：</span><span>重庆巴南区木洞镇国际生物城</span></p>\n' +
              '    <p><span style="margin-left: 80px">重庆市江北区五里店科技金融中心人保财险大厦</span></p>\n' +
              '    <p><span>待遇：面议</span></p>\n' +
              '    <p><span>简历投递邮箱：</span><u>swengin@163.com</u></p>\n' +
              '    <p><span>联系电话：13527548045，匡经理</span></p>\n' +
              '    <p><br></p>'
        },
        {
          id: 3,
          name: "研发工程师-分离工程（12K-24K/月）",
          place: "重庆",
          judge: false,
          judges: false,
          content: '<p><strong>一、岗位职责</strong></p>\n' +
              '    <p><span>1、负责待分离物系分离过程概念方案设计，应用包括精馏、结晶、萃取等分离技术实现特定分离目标；</span></p>\n' +
              '    <p><span>2、依据概念方案开展实验验证，据验证结果改进概念方案并继续验证，直至达到预期目标；</span></p>\n' +
              '    <p><span>3、收集测试数据，测量基础物性数据，形成解决方案；</span></p>\n' +
              '    <p><span>4、将解决方案提交工艺包开发人员，配合工艺包开发人员完成工艺包开发设计工作。</span></p>\n' +
              '    <p><strong>二、任职条件</strong></p>\n' +
              '    <p><span>1.教育背景：化学反应工程、化学工程与工艺、化工分离过程相关专业硕士/本科；</span></p>\n' +
              '    <p><span>2.工作经验：熟练掌握结晶、精馏、萃取、吸附、干燥等分离技术，熟悉各种分析表征方法；</span></p>\n' +
              '    <p><span>3.综合素质：热爱研发工作，熟练掌握计算机办公软件，有较强的组织能力和沟通、表达能力。</span><span>。</span></p>\n' +
              '    <p><span>&nbsp;</span></p>\n' +
              '    <p><span>工作地点：</span><span>重庆巴南区木洞镇国际生物城</span></p>\n' +
              '    <p><span style="margin-left: 80px">重庆市江北区五里店科技金融中心人保财险大厦</span></p>\n' +
              '    <p><span>待遇：面议</span></p>\n' +
              '    <p><span>简历投递邮箱：</span><u>swengin@163.com</u></p>\n' +
              '    <p><span>联系电话：13527548045，匡经理</span></p>\n' +
              '    <p><br></p>'
        },
        {
          id: 4,
          name: "化工工艺工程师（12K-24K/月）",
          place: "重庆",
          judge: false,
          judges: false,
          content: '<p><strong>一、岗位职责</strong></p>\n' +
              '    <p><span>1、确定生产装置的工艺流程，进行工艺计算完成物料与热量衡算，提出工艺流程图（PFD）；</span></p>\n' +
              '    <p><span>2、提出仪表一次元件、热工类设备（换热器、工业炉、火炬等）、机泵类设备（输油泵、加药泵、工艺气体压缩机等）的工艺条件；</span></p>\n' +
              '    <p><span>3、计算完成工艺设备一览表；</span></p>\n' +
              '    <p><span>4、确定工艺设备规格及技术要求，编制工艺设备数据表；</span></p>\n' +
              '    <p><span>5、提出建议的设备布置方案；</span></p>\n' +
              '    <p><span>6、完成供各专业做准备和开展工作用的管道及仪表流程图(A版PID)；</span></p>\n' +
              '    <p><span>7、编写工艺说明文件；</span></p>\n' +
              '    <p><span>8、绘制装置所有工艺施工图；</span></p>\n' +
              '    <p><span>9、编制和修订产品操作维修手册及质量文件；</span></p>\n' +
              '    <p><span>10、编制工艺包；</span></p>\n' +
              '    <p><span>11、上完成级领导交办的其他事项。</span></p>\n' +
              '    <p><strong>二、任职条件</strong></p>\n' +
              '    <p><span>1、能准确理解国际标准和技术文件要求</span></p>\n' +
              '    <p><span>2、化工工艺与工程、过程装备与控制等专业，大学本科以上学历，40周岁以下；</span></p>\n' +
              '    <p><span>3、三年以上相关工作经验（有化工设计院撬装设备工艺设计工作经验优先）；</span></p>\n' +
              '    <p><span>4、具有扎实的专业知识，较强的学习能力、思维能力和分析解决问题的能力；</span></p>\n' +
              '    <p><span>5、良好的沟通能力，严谨的工作态度，探索创新和团队协作精神；</span></p>\n' +
              '    <p><span>6、职业道德良好，人品端正、责任心强、积极热情、乐于助人。</span></p>\n' +
              '    <p><span>&nbsp;</span></p>\n' +
              '    <p><span>工作地点：</span><span>重庆巴南区木洞镇国际生物城</span></p>\n' +
              '    <p><span style="margin-left: 80px">重庆市江北区五里店科技金融中心人保财险大厦</span></p>\n' +
              '    <p><span>待遇：面议</span></p>\n' +
              '    <p><span>简历投递邮箱：</span><u>swengin@163.com</u></p>\n' +
              '    <p><span>联系电话：13527548045，匡经理</span></p>\n' +
              '    <p><br></p>'
        },
        {
          id: 5,
          name: "化工技术装备销售工程师（6K-10K/月）",
          place: "重庆",
          judge: false,
          judges: false,
          content: '<p><strong>一、岗位职责</strong></p>\n' +
              '    <p><span>1. 收集市场信息，开展市场研究，搜寻潜在市场销售机会；</span></p>\n' +
              '    <p><span>2. 收集潜在客户资料。</span></p>\n' +
              '    <p><span>3. 了解和发掘客户需求及核心关切，制定满足客户需求的推广方案；</span></p>\n' +
              '    <p><span>4. 对客户提供专业的咨询；</span></p>\n' +
              '    <p><span>5. 管理客户关系，拟定销售计划，完成销售任务</span></p>\n' +
              '    <p><strong>二、任职条件</strong></p>\n' +
              '    <p><span>1、应用化学、化工工程与工艺、化学相关专业本科及以上学历；</span></p>\n' +
              '    <p><span>2、良好的沟通、表达和人际交往能力，具有较强的客户服务意识和团队合作精神；</span></p>\n' +
              '    <p><span>3、具有较强的学习能力和工作激情，能快速掌握所售设备的技术资料 ；</span></p>\n' +
              '    <p><span>4、热爱销售工作,习惯出差，做事思路清晰；</span></p>\n' +
              '    <p><span>5、强烈的自驱力和好奇心，善于思考。</span></p>\n' +
              '    <p><span>&nbsp;</span></p>\n' +
              '    <p><span>工作地点：</span><span>重庆巴南区木洞镇国际生物城</span></p>\n' +
              '    <p><span style="margin-left: 80px">重庆市江北区五里店科技金融中心人保财险大厦</span></p>\n' +
              '    <p><span>待遇：面议</span></p>\n' +
              '    <p><span>简历投递邮箱：</span><u>swengin@163.com</u></p>\n' +
              '    <p><span>联系电话：13527548045，匡经理</span></p>\n' +
              '    <p><br></p>'
        },
      ],
      wapShow: false
    }
  },
  mounted() {
    this.selectIndex = this.$route.query.index ? this.$route.query.index : 1;

    //切换手机端
    if (this.$route.query) this.wapShow = this.$route.query.wapShow;
    eventBus.$on("wapShow", (data) => {
      this.wapShow = data;
    })
  },
  methods: {},
  watch: {
    '$route'() {
      this.selectIndex = this.$route.query.index ? this.$route.query.index : 1
    }
  }
}
</script>

<style scoped>
/*@import "../../assets/css/about.css";*/
a {
  text-decoration: none;
}


.Technology-header {
  margin-top: 17px;
  display: flex;
}

.Technology-header .header-li {
  position: absolute;
  left: 252px;
  top: 0;
  background: #e6e8eb;
}

.Technology-header .left {
  width: 252px;
  height: 201px;
  padding: 20px 0px;
  background: #e6e8eb;
  box-sizing: border-box;
  border-radius: 10px 0px 0px 10px;
}

.Technology-header .lefts {

  height: 240px !important;
}

.Technology-header .left span {
  width: 252px;
  display: flex;
  height: 36px;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #363a44;
  padding-left: 44px;
  cursor: pointer;
  box-sizing: border-box;
}

.Technology-header .left span:hover {
  background: rgba(184, 209, 239, 1);
}

.Technology-header .left span.select {
  background: #0079ff;
  color: #ffffff;
}

.Technology-header .right {
  width: 848px;
  height: 201px;
}

.Technology-header .rights {
  width: 848px;
  height: 240px;
}

.Technology-header .rights .img1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Technology-header .right .img1 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-centent {
  margin-top: 30px;
  position: relative;
  font-size: 16px;
}

.about-centent .content1 {
  position: relative;
}

.about-centent .about-title {
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(54, 58, 68, 0.08);
  margin-bottom: 30px;
}

.about-centent .about-title-zh {
  font-size: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #363A44;
  letter-spacing: 4px;
  position: absolute;
  top: 31px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.about-centent .p2 {
  width: 916px;
  height: 395px;
  background: #EDF1F4;
  border-radius: 10px;
  margin-left: 184px;

}

.about-centent .p2 .text1 {
  width: 550px;
  height: 308px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #363A44;
  line-height: 28px;
  margin-left: 340px;
  text-align: justify;
  padding-top: 33px;
}

.about-centent .i1 {
  width: 490px;
  height: 326px;
  float: left;
  margin-top: 27px;
}

.about-centent .i2 {
  width: 1101px;
  height: 517px;
  margin: 20px auto 40px;
  object-fit: contain;
}

.about-centent .about-img1 {
  background-image: url("../../assets/image/about/6.png");
  width: 1100px;
  height: 330px;
  background-size: 100% 100%;
  margin: 50px auto 40px;
}

.about-centent .about-img1 i {
  width: 30px;
  height: 30px;
  border: 1px solid #EEEFF0;
  border-radius: 50%;
  justify-content: center;
  float: left;
  margin-right: 10px;
}

.about-centent .about-img1 .right {
  width: 550px;
  height: 330px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 1px;
  backdrop-filter: blur(10px);
  float: right;
  text-align: left;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #363A44;
  padding-left: 57px;
}

.about-centent .c2-p1 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #363A44;
  text-indent: 2em;
  text-align: left;
  line-height: 1.8;
  margin-top: 45px;
}

.about-centent .c2-box {
  margin-top: 35px;
}

.about-centent .c2-li .borderb {
  position: relative;
  top: 7px;
  height: 1px;
  width: 1060px;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px dashed #979797;
}

.about-centent .c2-li {
  width: 1100px;
  height: 64px;
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #EFEFEF;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 30px;
  box-sizing: border-box;
}

.about-centent .c2-li:hover {
  cursor: pointer;
}

.about-centent .c2-li .t1 {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #363A44;
}

.about-centent .c2-li .t2 {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin: 0 18px 0 6px;
  color: #9DA6AC;
}

.about-centent .c2-li-select {
  background: #F8FBFD;
  /*border-bottom: 1px dashed  #979797;*/
  border-radius: 5px 5px 0 0;
  margin-bottom: 0;
  border: 0;
}

.about-centent .c2-li-select .flex-center {
  padding-top: 10px;
}

.about-centent .c2-li-select .iconfont {
  padding-top: 1px;
}

.about-centent .cons {
  background: #F8FBFD;
  text-align: left;
  box-sizing: border-box;
  padding: 20px 20px 20px 30px;
  margin-bottom: 20px;
  color: rgb(51, 51, 51);
  font-family: Arial;
}

.about-centent .cons p {
  margin: 10px 0;
}

.flex-center {
  display: flex;
  align-items: center;
  /*padding-top:10px;*/
}

</style>
