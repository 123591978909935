<template>
    <div class="messages">
       <!-- <div class="navigation-box">
            <router-link to="/">首页</router-link><span style="margin: 0 3px">></span>
            资讯与动态
        </div> -->
        <div class="message-header"></div>

        <div class="message-content">
			<div class="right" >
			    <div class="r1">推荐资讯</div>
			    <div class="swiper-container">
			        <div class="swiper-wrapper">
			            <div class="swiper-slide" v-for="item in list" @click="to_Detail(item)" style="cursor: pointer">
			                <img :src="require('@/assets/image/message/'+item.imgurl)" style="border-radius: 10px;"/>
			                <div class="span1"><span>{{item.name}}</span></div>
			            </div>
			        </div>
			    </div>
                <div class="swiper-pagination"></div>
			</div>

            <div class="left" style="margin-top:30px;">
                <div class="left-li" v-for="item in list" @click="to_Detail(item)">
                    <img :src="require('@/assets/image/message/'+item.imgurl)" style="border-radius: 10px;"/>
                    <div class="text">
                        <p class="t1">{{item.name}}</p>
                        <p class="t2">{{item.contents}}</p>
                        <p class="t3">{{item.time}}</p>
                    </div>
                </div>
            </div>
        </div>

        <mobileFooter></mobileFooter>
    </div>
</template>

<script>
    import Swiper from "swiper";
    import mobileFooter from '../../../components/mobile_footer';

    export default {
        name: "mobileMessage",
        components:{mobileFooter},
        data(){
            return{
                list:[]
            }
        },
        mounted() {
            this.list = require('@/assets/js/data.js').default;
            document.getElementsByTagName("title")[0].innerText = '资讯与动态_重庆敏恒科技有限公司';
            this.$nextTick(()=>{
                let swiper1=new Swiper('.swiper-container', {
                    autoplay: {
                        pauseOnMouseEnter: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                })
            })
        },
        methods:{
            to_Detail(data){
                this.$router.push({
                    name:"messageDetail",
                    query:{
                        id:data.id,
                        wapShow:true
                    }
                })
            }
        }
    }
</script>

<style scoped>

    /*@import "../../../assets/css/mobileMessage.css";*/

    .message-header{
        margin:0 auto 0;
        width: 355px;
        height: 68px;
        background-image: url("../../../assets/image/mobile/zixun.png");
        background-size: 100% 100%;
        border-radius: 10px;
    }

    .message-content{
        width:355px;
        margin:15px auto 0;
        /* display: flex;
        justify-content: space-between;
        width: 100%; */
    }
    .message-content .left-li{
        width: 355px;
        height: 97.88px;
        background: #FFFFFF;
        /* box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.06); */
        border-radius: 10px;
        margin-bottom: 20px;
        display: flex;
        cursor: pointer;
    }
    .message-content .left-li img{
        width: 151px;
        height: 97px;
        object-fit: contain;
    }
    .message-content .left-li .text{
        width: 502px;
        height: 161px;
        box-sizing: border-box;
        text-align: left;
        padding-left: 11px;
        padding-right: 27px;
    }
    .message-content .left-li .text .t1{
        width: 189px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3A3A3A;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 4px 0 0;
    }
    .message-content .left-li .text .t1:hover{
        color: #0079FF;
    }
    .message-content .left-li .text .t2{
        height: 35px;
        width: 192px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #3A3A3A;
        line-height: 17px;
        margin: 1px 0 0;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .message-content .left-li .text .t3{
        width: 193px;
        height: 17px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #9B9DA2;
        margin: 20px 0 0;
    }
    .message-content .right{
        width: 355px;
        text-align: left;
        position: relative;
        margin:17px auto 0;
    }

    .message-content .right .r1{
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3A3A3A;
        line-height: 18px;
    }

    .message-content .right .swiper-container{
        width: 355px;
        height: 168px;
        margin-top: 10px;
        border-radius: 10px;
    }
    .message-content .right .swiper-container img{
        width: 355px;
        height:168px;
        object-fit: cover;
    }
    .message-content .right .swiper-container .span1{
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 4;
        display: block;
        height: 40px;
        overflow: hidden;
        left: 0;
        background-image: url("../../../assets/image/meng.png");
        background-size: 100% 100%;
        font-size: 13px;
        line-height: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        padding: 8px 9px 8px;
        box-sizing: border-box;
    }
    .message-content .right .swiper-container span{
        height:35px;
        width: 100%;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .swiper-button-prev{
        left: auto;
        top: 13px;
        right: 32px;
    }
    .swiper-button-prev .img1{
        width: 20px;
        height: 20px;
        background-image: url("../../../assets/image/message/left.png");
        background-size: 100% 100%;
    }
    .swiper-button-prev .img1:hover{
        background-image: url("../../../assets/image/message/right.png");
        background-size: 100% 100%;
        transform:rotate(180deg);
        -ms-transform:rotate(180deg); 	/* IE 9 */
        -moz-transform:rotate(180deg); 	/* Firefox */
        -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
        -o-transform:rotate(180deg); 	/* Opera */
    }
    .swiper-button-next{
        left: auto;
        top: 13px;
        right: 0px;
    }
    .swiper-button-next:after,.swiper-button-prev:after{
        display: none;
    }
    .swiper-button-next .img1{
        width: 20px;
        height: 20px;
        background-image: url("../../../assets/image/message/left.png");
        background-size: 100% 100%;
        transform:rotate(180deg);
        -ms-transform:rotate(180deg); 	/* IE 9 */
        -moz-transform:rotate(180deg); 	/* Firefox */
        -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
        -o-transform:rotate(180deg); 	/* Opera */
    }
    .swiper-button-next .img1:hover{
        background-image: url("../../../assets/image/message/right.png");
        background-size: 100% 100%;
        transform:rotate(0deg);
        -ms-transform:rotate(0deg); 	/* IE 9 */
        -moz-transform:rotate(0deg); 	/* Firefox */
        -webkit-transform:rotate(0deg); /* Safari 和 Chrome */
        -o-transform:rotate(0deg); 	/* Opera */
    }
    .message-content .right .swiper-pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        width:355px;
        height:3px;
        top:205px;
        margin:0 auto 0;
    }

    .message-content .right .swiper-pagination .swiper-pagination-bullet{
        width: 6px;
        height: 6px;
        background: rgba(0,121,255,0.4);
        /*margin-right:3px !important;*/
    }

    .message-content .right .swiper-pagination .swiper-pagination-bullet-active{
        background: #0079FF;
    }
    .messages{
        width: 375px;
        background: #FFFFFF;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        margin:10px auto 0;
    }


</style>
