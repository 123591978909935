<template>
    <div>
        <template v-if="!wapShow">
            <div>
                <div class="navigation-box">
                    <router-link to="/">首页</router-link><span style="margin: 0 3px">></span>
                    {{selectIndex==1?'研究测试':selectIndex==2?'工程设计':selectIndex==3?'设备制造和系统集成':selectIndex==4?'交付及售后服务':''}}
                </div>

                <div class="Technology-header">
                    <div class="lefts left">
                        <span style="padding-left: 21px; background: none;cursor: auto;font-family: PingFangSC-Medium, PingFang SC">服务流程</span>
                        <router-link to="/server?index=1">
                <span :class="{ select: selectIndex == 1 }" @click="selectIndex = 1"
                >研究与测试</span
                >
                        </router-link>
                        <router-link to="/server?index=2">
                <span
                        style="position: relative"
                        :class="{ select: selectIndex == 2 }"
                        @click="selectIndex = 2"
                        @mousemove="lishow = true"
                        @mouseout="lishow = false"
                >工程设计
        </span>
                        </router-link>
                        <router-link to="/server?index=3"> <span :class="{ select: selectIndex == 3 }"
                        >设备制造和系统集成</span
                        ></router-link>

                        <router-link to="/server?index=4"><span :class="{ select: selectIndex == 4 }"
                        >交付及售后服务</span
                        >
                        </router-link>
                    </div>
                    <div class="rights">
                        <template v-if="selectIndex == 1">
                            <img src="@/assets/image/server/1.png" class="img1"/>
                        </template>

                        <template v-if="selectIndex == 2">
                            <img src="@/assets/image/server/2.png" class="img1"/>
                        </template>


                        <template v-if="selectIndex == 3">
                            <img src="@/assets/image/server/4.png" class="img1"/>
                        </template>
                        <template v-if="selectIndex == 4">
                            <img src="@/assets/image/server/14.png" class="img1"/>
                        </template>
                    </div>
                </div>

                <div class="Technology-content">
                    <template v-if="selectIndex == 1">
                        <div class="title1">
                            研究与测试——原因
                        </div>
                        <div class="text">
          <span style="text-indent: 2em; line-height: 2;margin-left: 10px;width: 1084px">
               	研究和测试是做出一项正确投资决策的关键，在选择适合的技术方案之前，关键的问题需要进行深入研究，不确定的事项需要进行详细验证。无论您是进行新工艺开发、建设新装置还是对老工艺或装置进行升级改造，我们都能通过研究和测试为您的决策提供支持。
          </span>

                            <span class="textHeader3" style="margin-bottom: 10px;margin-top: 17px">研究的基础和内容</span>

                            <span style="text-indent: 2em; line-height: 2;font-size: 14px;width: 1084px;white-space: nowrap;">
               	您的要求是我们研究和测试的基础，通过理论分析或试验测试，我们能够在一些您还不清楚的具体细节和您的疑问方面为你提供支持。研究和测试阶段，我们将开展如下工作：
          </span>
                            <span class="textHeader2"
                            >收集和测取基础数据</span
                            >
                            <span class="textHeader2"
                            >进行概念设计</span
                            >
                            <span class="textHeader2"
                            >开展实验验证和测试</span
                            >
                            <span class="textHeader2"
                            >完善概念设计并进行技术经济分析</span
                            >

                            <span class="textHeader3" style="margin-bottom: 10px;margin-top: 20px">研究的结果</span>
                            <span style="text-indent: 2em; line-height: 2;margin-left: 10px;width: 1084px">
                        	研究和测试结果将形成一份概念设计文件，为你提供下一步决策所需要的关键信息，包括技术、预期的产品质量、能耗和投资估算等详细信息。概念设计文件通常包含下列信息：
          </span>

                            <span class="textHeader2"
                            >设计基础</span
                            >
                            <span class="textHeader2"
                            >工艺说明</span
                            >
                            <span class="textHeader2"
                            >工艺流程简图</span
                            >
                            <span class="textHeader2"
                            >主要工艺数据</span
                            >
                            <span class="textHeader2"
                            >主要耗能数据</span
                            >
                            <span class="textHeader2"
                            >关键设备清单</span
                            >
                            <span class="textHeader2" style="margin-bottom: 40px"
                            >投资估算</span
                            >
                        </div>
                    </template>

                    <template v-if="selectIndex == 2">
                        <div class="title1">
                            工程设计
                        </div>
                        <span style="text-indent: 2em; line-height: 2;font-size: 14px;width: 1084px;margin:0 auto 0 auto;">
                    	敏恒科技善于应用深厚的专业知识和丰富的实践经验，有效和务实的将客户复杂的需求转变为清晰的解决方案。作为客户的技术服务商和装备供应商，敏恒科技致力于成为合作伙伴工程项目建设所有阶段的顾问。我们的工程设计服务范围包括： 
          </span>
                        <span class="textHeader2" style="margin-left: 32px"
                        >基础工程设计</span
                        >
                        <span class="textHeader2" style="margin-left: 32px"
                        >配合详细工程设计</span
                        >
                        <span class="textHeader2" style="margin-left: 32px"
                        >提供安装、调试和试生产指导服务</span
                        >

                        <span class="textHeader3" style="margin-bottom: 10px;margin-top: 17px">基础工程设计</span>

                        <span style="text-indent: 2em; line-height: 2;margin-left: 10px;width: 1084px;font-size: 14px">
                    	基础工程设计工艺将为一个新装置的建设提供必要的工艺技术信息，基础工程设计工艺的内容和深度将根据客户的具体要求确定。我们基础工程设计工艺的内容通常包括：
                 </span>
                        <img src="@/assets/image/server/3.png" class="img4" style="height: 480px;margin: 20px auto 40px" />
                    </template>

                    <template v-if="selectIndex == 3">
                        <div class="title1">
                            设备制造
                        </div>
                        <span style="text-indent: 2em; line-height: 2;font-size: 14px;width: 1084px;margin:0 auto 0 auto;">
                    	我们的核心设备，是经验丰富的专家数十年经验积累和不断改进的结果，处于相关领域的前沿。经过专门设计和定制，可以实现设备性能和工艺要求的最佳匹配，最大程度的延长设备使用寿命，降低维护成本，提升您的利润。
          </span>

                        <div class="imngbox2" style="margin-bottom: 20px;margin-top: 14px">
                            <div class="imgboxs100">
                                <viewer :images="images">
                                    <img v-for="(src,index) in images" :src="src" v-show="index==0" class="serverImg"/>
                                </viewer>
                                <span class="imgText">激光焊机生产线</span>
                            </div>
                            <div class="imgboxs100">
                                <viewer :images="images">
                                    <img v-for="(src,index) in images" :src="src" v-show="index==1" class="serverImg"/>
                                </viewer>
                                <span class="imgText">渗透性着色试验 1.1</span>
                            </div>
                            <div class="imgboxs100">
                                <viewer :images="images">
                                    <img v-for="(src,index) in images" :src="src" v-show="index==2" class="serverImg"/>
                                </viewer>
                                <span class="imgText">渗透性着色试验 1.2</span>
                            </div>
                        </div>

                        <div class="imngbox4" style="margin-bottom: 40px">
                            <div class="imgboxs100">
                                <viewer :images="images">
                                    <img v-for="(src,index) in images" :src="src" v-show="index==3" class="serverImg"/>
                                </viewer>
                                <span class="imgText"
                                >组装成品</span
                                >
                            </div>
                            <div class="imgboxs100">
                                <viewer :images="images">
                                    <img v-for="(src,index) in images" :src="src" v-show="index==4" class="serverImg"/>
                                </viewer>
                                <span class="imgText"
                                >整体水压试验</span
                                >
                            </div>
                        </div>
                        <div class="title1">
                            系统集成
                        </div>
                        <span style="text-indent: 2em; line-height: 2;font-size: 14px;margin-bottom: 16px;width:1084px;margin:0 auto 0 auto;">
         	我们的撬装装置或现场安装系统，由我们经验丰富的专家、经过验证的工作程序、以及现代化的加工和焊接设备生产。我们经过审核的分包商确保严格地遵守生产计划，高效及时的交付助您加速将产品推向市场，获取竞争优势。</span>
                        <div class="imngbox4" style="margin-bottom: 20px">
                            <div class="imgboxs100">
                                <viewer :images="images">
                                    <img v-for="(src,index) in images" :src="src" v-show="index==5" class="serverImg"/>
                                </viewer>
                                <span class="imgText"
                                >撬装车间</span
                                >
                            </div>
                            <div class="imgboxs100">
                                <viewer :images="images">
                                    <img v-for="(src,index) in images" :src="src" v-show="index==6" class="serverImg"/>
                                </viewer>
                                <span class="imgText"
                                >设备制造车间</span
                                >
                            </div>
                        </div>

                        <div class="imngbox4" style="margin-bottom: 40px">
                            <div class="imgboxs100">
                                <viewer :images="images">
                                    <img v-for="(src,index) in images" :src="src" v-show="index==7" class="serverImg"/>
                                </viewer>
                                <span class="imgText"
                                >脱水单元撬装产品</span
                                >
                            </div>
                            <div class="imgboxs100">
                                <viewer :images="images">
                                    <img v-for="(src,index) in images" :src="src" v-show="index==8" class="serverImg"/>
                                </viewer>
                                <span class="imgText"
                                >分离单元撬装产品</span
                                >
                            </div>
                        </div>
                    </template>

                    <template v-if="selectIndex == 4">
                        <div class="title1">
                            交付及售后服务
                        </div>
                        <span class="textHeader2"
                        >我们的售后服务由您的要求决定。</span
                        >
                        <span class="textHeader2"
                        >我们经验丰富的调试工程师现场指导调试和过程操作优化，以及为客户进行员工培训，直至装置能够稳定良好的运行。</span
                        >
                        <span class="textHeader2"
                        >如果突然发生故障，我们将尽最大努力在当天派遣有经验的服务工程师</span
                        >
                        <span class="textHeader2"
                        >如果设备发生严重损坏，我们配套供应商可提供经验丰富且设备齐全的维修设施。维修工作队将立即进行合格的维修，以确保设备或装置尽快回到运行状态。</span
                        >

                        <div class="title1" style="margin-top: 40px">
                            我们的服务
                        </div>

                        <div class="img-boxs">
                            <img src="@/assets/image/server/15.png">
                        </div>
                    </template>
                </div>

            </div>
        </template>

        <template v-else>
            <server-wap></server-wap>
        </template>
    </div>

</template>

<script>
    import eventBus from "@/assets/js/eventBus";
    import serverWap from "./wap/index"

    export default {
        name: "server",
        components: {serverWap},
        data() {
            return {
                selectIndex: 1,
                lishow: false,
                pageIndex: 1,
                images:[
                    require('@/assets/image/server/5.png'),
                    require('@/assets/image/server/6.png'),
                    require('@/assets/image/server/7.png'),
                    require('@/assets/image/server/8.png'),
                    require('@/assets/image/server/9.png'),
                    require('@/assets/image/server/10.png'),
                    require('@/assets/image/server/11.png'),
                    require('@/assets/image/server/12.png'),
                    require('@/assets/image/server/13.png')
                ],
                wapShow:false
            };
        },
        mounted() {
            this.selectIndex=this.$route.query.index?this.$route.query.index:1;
            document.getElementsByTagName("title")[0].innerText = '服务流程_重庆敏恒科技有限公司';

            //切换手机端
            if(this.$route.query) this.wapShow = this.$route.query.wapShow;
            eventBus.$on("wapShow",(data)=>{
                this.wapShow= data;
            })
        },
        watch:{
            '$route'(){
                this.selectIndex=this.$route.query.index?this.$route.query.index:1;
            }
        }
    };
</script>

<style scoped>
    /*@import "../../assets/css/technology.css";*/
    a{
        text-decoration: none;
    }
    .Technology-content .textHeader2::after {
        background-color: rgba(0, 121, 255, 1) !important;
    }

    .img-boxs{
        margin:20px 0 40px 8px;
    }

/*technology.css*/
    .Technology-header {
        margin-top: 17px;
        display: flex;
    }
    .Technology-header .header-li {
        position: absolute;
        left: 252px;
        top: 0;
        background: #e6e8eb;
    }
    .Technology-header .left {
        width: 252px;
        height: 201px;
        padding: 20px 0px;
        background: #e6e8eb;
        box-sizing: border-box;
        border-radius: 10px 0px 0px 10px;
    }
    .Technology-header .lefts {

        height: 240px !important;
    }
    .Technology-header .left span {
        width: 252px;
        display: flex;
        height: 36px;
        align-items: center;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        font-weight: 500;
        color: #363a44;
        padding-left: 44px;
        cursor: pointer;
        box-sizing: border-box;
    }
    .Technology-header .left span:hover {
        background: rgba(184, 209, 239, 1);
    }
    .Technology-header .left span.select {
        background: #0079ff;
        color: #ffffff;
    }
    .Technology-header .right {
        width: 848px;
        height: 201px;
    }
    .Technology-header .rights {
        width: 848px;
        height: 240px;
    }
    .Technology-header .rights .img1 {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .Technology-header .right .img1 {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .Technology-content {
        margin-top: 30px;
        text-align: justify;
    }
    .Technology-content .title1 {
        padding: 0 10px;
        width: fit-content;
        height: 40px;
        border-radius: 10px;
        border: 1px solid #0079ff;
        font-size: 20px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0079ff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .Technology-content span {
        display: block;
    }
    .Technology-content .text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3a3a3a;
        display: block;
    }
    .Technology-content .textHeader {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-bottom: 14px;
        display: block;
        position: relative;
        padding-left: 20px;
        margin-left:8px;
    }
    .Technology-content .textHeader::after {
        content: "";
        width: 14px;
        height: 14px;
        position: absolute;
        background-image: url(../../assets/image/technology/0.png);
        background-size: 100% 100%;
        left: 0px;
        top: 4px;
    }
    .Technology-content .textHeader2 {
        margin-left: 25px;
        margin-bottom: 8px;
        position: relative;
        padding-left: 20px;
        margin-top: 16px;
        font-size: 14px;
        letter-spacing: 1px;
    }
    .Technology-content .text2 {
        margin-left: 40px;
        margin-bottom: 8px;
        line-height: 2;
    }
    .Technology-content .textHeader2::after {
        content: "";
        position: absolute;
        left: 4px;
        width: 6px;
        height: 6px;
        top: 8px;
        background: #6a6a6a;
    }
    .Technology-content .img2 {
        width: 1084px;
        height: 240px;
        object-fit: cover;
        margin: 0 auto;
        display: block;
    }
    .Technology-content .img4 {
        width: 1084px;
        height: 240px;
        object-fit: cover;
        margin: 0 auto;
        display: block;
        border-radius: 5px;
    }
    .Technology-content .img2:hover{
        cursor: pointer;
    }
    .Technology-content .img3:hover{
        cursor: pointer;
    }
    .Technology-content .imgText {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #363a44;
        text-align: center;
        margin-top: 8px;
    }
    .Technology-content .imngbox {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin:0 auto 0 auto;
    }
    .Technology-content .imngbox img {
        width: 340px;
        height: 240px;
        object-fit: contain;
    }
    .Technology-content .imngbox1 {
        display: flex;
        justify-content: space-between;
        align-content: center;
        /*width: 1084px;*/
    }
    .Technology-content .imngbox1 img {
        width: 516px;
        height: 240px;
        object-fit: contain;
    }
    .Technology-content .imngbox2 {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .Technology-content .imngbox2 img {
        width: 354px;
        height: 452px;
        object-fit: contain;
    }

    .Technology-content .imngbox4 {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .Technology-content .imngbox4 img {
        width:536px;
        height: 305px;
        object-fit: contain;
    }
    .Technology-content .btns {
        width: 112px;
        height: 38px;
        background: #0079ff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin-top: 30px;
        margin-left: 20px;
        margin-bottom: 40px;
    }

    .Technology-content .imngbox3 {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .Technology-content .imngbox3 img {
        width: 1084px;
        height: 240px;
        object-fit: cover;
    }
    .Technology-content .textHeader3 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        padding: 5px 20px;
        background: #0079FF;
        border-radius: 5px;
        width: fit-content;
        margin-left: 8px;
    }
    .model-box{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }
    .model-box .model-bg{
        background-color: #00000070;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .model-box .model-content{
        width: 300px;
        height: 200px;
        border-radius: 10px;
        background-color: #ffffff;
        position: relative;
        z-index: 99;
        line-height: 1.6;
        padding-top: 40px;
        box-sizing: border-box;
        font-size: 16px;
    }
    .model-box .model-content .btn1{
        width: 80px;
        height: 40px;
        background-color: #0079ff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #ffffff;
        border-radius: 6px;
        margin:  20px auto 0;
    }

    /*熔融结晶特点*/

    .flex-col {
        display: flex;
        flex-direction: column;
    }
    .flex-row {
        display: flex;
        flex-direction: row;
    }
    .main12 {
        width: 1084px;
        height: 162px;
        margin: 14px 0 0 8px;
    }
    .main14 {
        width: 1084px;
        height: 337px;
        margin: 12px 0 0 8px;
    }
    .justify-between {
        display: flex;
        justify-content: space-between;
    }

    /*操作温度低*/
    .wrap11 {
        height: 162px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
        width: 536px;
    }

    .mod6 {
        width: 494px;
        height: 97px;
        margin: 29px 0 0 20px;
    }

    .mod7 {
        width: 350px;
        height: 83px;
    }

    .word66 {
        width: 96px;
        height: 26px;
        display: block;
        overflow-wrap: break-word;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        white-space: nowrap;
        line-height: 26px;
        text-align: left;
        font-weight: 500;
        color: #3A3A3A;
    }

    .word67 {
        width: 350px;
        height: 52px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 14px;
        line-height: 26px;
        text-align: left;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
    }

    .mod8 {
        z-index: 163;
        position: relative;
        width: 124px;
        height: 90px;
        border-radius: 5px;
        overflow: hidden;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16856d2a4b391a5689d0491daf3be0ccb51e0c4f13fb91bf8b77c3dde4b064c0)
        100% no-repeat;
        margin-top: 7px;
    }

    .img5 {
        z-index: 164;
        position: absolute;
        left: 0;
        top: 0;
        width: 124px;
        height: 90px;
    }
    /*操作温度低end*/

    /*无溶剂*/
    .wrap12 {
        height: 162px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
        width: 536px;
    }

    .main13 {
        width: 494px;
        height: 97px;
        margin: 29px 0 0 20px;
    }

    .group19 {
        width: 350px;
        height: 83px;
    }

    .word68 {
        width: 64px;
        height: 26px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 16px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 26px;
        text-align: left;
    }

    .word69 {
        width: 350px;
        height: 52px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 14px;
        line-height: 26px;
        text-align: left;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .group20 {
        z-index: 172;
        position: relative;
        width: 124px;
        height: 90px;
        border-radius: 5px;
        overflow: hidden;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16856d2a4b391a5689d0491daf3be0ccb51e0c4f13fb91bf8b77c3dde4b064c0)
        100% no-repeat;
        margin-top: 7px;
    }

    .img6 {
        z-index: 173;
        position: absolute;
        left: 0;
        top: 0;
        width: 124px;
        height: 90px;
    }
    /*无溶剂end*/

    /*能耗低*/
    .box8 {
        height: 337px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
        width: 536px;
    }

    .section38 {
        width: 495px;
        height: 275px;
        margin: 29px 0 0 20px;
    }

    .info27 {
        width: 64px;
        height: 26px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 16px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 26px;
        text-align: left;
    }

    .word70 {
        width: 495px;
        height: 104px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 14px;
        line-height: 26px;
        text-align: left;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .outer15 {
        z-index: 201;
        position: relative;
        width: 495px;
        height: 94px;
        border-radius: 5px;
        overflow: hidden;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd646b8319da93546b23c3b0e314c0223044d3b48b1d7cec6c28cf660c71b1a68)
        100% no-repeat;
        margin-top: 46px;
    }

    .img7 {
        z-index: 202;
        position: absolute;
        left: 0;
        top: 0;
        width: 495px;
        height: 94px;
    }
    /*能耗低end*/

    /*应用范围广*/
    .group21 {
        height: 162px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
        width: 536px;
    }

    .layer14 {
        width: 494px;
        height: 109px;
        margin: 29px 0 0 20px;
    }

    .mod9 {
        width: 350px;
        height: 109px;
    }

    .txt23 {
        width: 96px;
        height: 26px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 16px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 26px;
        text-align: left;
    }

    .txt24 {
        width: 350px;
        height: 78px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 14px;
        line-height: 26px;
        text-align: left;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mod10 {
        z-index: 191;
        position: relative;
        width: 124px;
        height: 90px;
        border-radius: 5px;
        overflow: hidden;
        /*background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16856d2a4b391a5689d0491daf3be0ccb51e0c4f13fb91bf8b77c3dde4b064c0)*/
        /*100% no-repeat;*/
        margin-top: 7px;
    }

    .pic2 {
        z-index: 192;
        position: absolute;
        left: 0;
        top: 0;
        width: 124px;
        height: 90px;
    }
    /*应用范围广end*/

    /*产品纯度高*/
    .group22 {
        height: 163px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
        margin-top: 12px;
        width: 536px;
    }

    .block4 {
        width: 494px;
        height: 97px;
        margin: 29px 0 0 20px;
    }

    .box10 {
        width: 350px;
        height: 57px;
    }

    .word71 {
        width: 96px;
        height: 26px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 16px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 26px;
        text-align: left;
    }

    .word72 {
        width: 350px;
        height: 26px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 14px;
        white-space: nowrap;
        line-height: 26px;
        text-align: left;
        margin-top: 5px;
    }

    .box11 {
        z-index: 182;
        position: relative;
        width: 124px;
        height: 90px;
        border-radius: 5px;
        overflow: hidden;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16856d2a4b391a5689d0491daf3be0ccb51e0c4f13fb91bf8b77c3dde4b064c0)
        100% no-repeat;
        margin-top: 7px;
    }

    .img8 {
        z-index: 183;
        position: absolute;
        left: 0;
        top: 0;
        width: 124px;
        height: 90px;
    }
    /*产品纯度高end*/
    .imgboxs100{
        position: relative;
    }

    .imgboxs100:hover:before{
        content: '';
        width: 39px;
        height: 39px;
        position: absolute;
        top: 10px;
        right: 20px;
        background-image: url("../../assets/image/big.png");
        background-size: 100% 100%;
    }
    .imgboxs100:before:hover{
        display: none;
    }
    .serverImg:hover{
        cursor: pointer;
    }


</style>
