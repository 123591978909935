<template>
    <div class="detail-main">
       <!-- <div class="navigation-box">
            <router-link to="/">首页</router-link><span style="margin: 0 3px">></span>
            <router-link to="/message">资讯与动态</router-link><span style="margin: 0 3px">></span>
            {{obj.name}}
        </div> -->
        <div class="detail-content">
            <div v-html="obj.content" style="text-align: justify;"></div>
        </div>
        <mobileFooter></mobileFooter>
    </div>
</template>

<script>
    import mobileFooter from '../../../components/mobile_footer';

    export default {
        name: "mobileMessageDetail",
        components:{mobileFooter},
        data() {
            return {
                obj:{content:""},
                list:[]
            }
        },
        mounted() {
            this.list = require('@/assets/js/data2.js').default;
            this.list.forEach((item)=>{
                if(item.id == this.$route.query.id){
                    this.obj=item;
                    document.getElementsByTagName("title")[0].innerText = item.name+'_重庆敏恒科技有限公司';
                }
            });
        }
    }
</script>

<style scoped>
    .detail-content {
		width:345px;
		margin:15px auto 0;
        text-align: left;
        margin-top: 30px;
    }
    .detail-main{
        width: 375px;
        background: #FFFFFF;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        margin:10px auto 0;
        font-size: 16px;
    }
</style>
