<template>
    <div class="serverM">
        <div class="server-header">
			<nav class="nav-box"  style="height:46px;">
			   <router-link to="/server?index=1&wapShow=true" class="serverNav" style="padding-top:7px;height:39px;">
				   <span :class="{ select: selectIndex == 1 }" @click="lishowclick(1)">
					研究与<br>测试
				   </span>
			   </router-link>
			   <router-link to="/server?index=2&wapShow=true"  class="serverNav" style="padding-top:7px;height:39px;">
			           <span
						   :class="{ select: selectIndex == 2 }"
						   @click="lishowclick(2)"
			                >工程<br>设计
			           </span>
			   </router-link>
			   <router-link to="/server?index=3&wapShow=true"  class="serverNav" style="padding-top:7px;height:39px;">
			     <span :class="{ select: selectIndex == 3 }" @click="lishowclick(3)">
					 设备制造和<br>系统集成</span>
			   </router-link>
				<router-link to="/server?index=4&wapShow=true"  class="serverNav" style="padding-top:7px;height:39px;">
					<span :class="{ select: selectIndex == 4 }" @click="lishowclick(4)">
				     交付及<br>售后服务</span>
				</router-link>
			 </nav>
            <div class="rights" style="margin-top:10px;">
                <template v-if="selectIndex == 1">
                    <img src="@/assets/image/server/1.png" class="img1"  style="border-radius: 5px;"/>
                </template>

                <template v-if="selectIndex == 2">
                    <img src="@/assets/image/server/2.png" class="img1" style="border-radius: 5px;"/>
                </template>


                <template v-if="selectIndex == 3">
                    <img src="@/assets/image/server/4.png" class="img1" style="border-radius: 5px;"/>
                </template>
                <template v-if="selectIndex == 4">
                    <img src="@/assets/image/server/14.png" class="img1" style="border-radius: 5px;"/>
                </template>
            </div>
        </div>

        <div class="Technology-content">
            <template v-if="selectIndex == 1">
                <div class="title1" style="height:42px;">
                    研究与测试——原因
                </div>
                <div class="text">
          <span style="text-indent: 2em; line-height: 2;margin:0 auto 0;width: 355px;">
               	研究和测试是做出一项正确投资决策的关键，在选择适合的技术方案之前，关键的问题需要进行深入研究，不确定的事项需要进行详细验证。无论您是进行新工艺开发、建设新装置还是对老工艺或装置进行升级改造，我们都能通过研究和测试为您的决策提供支持。
          </span>

                    <span class="textHeader3" style="margin-bottom: 10px;margin-top: 17px">研究的基础和内容</span>

                    <span style="text-indent: 2em; line-height: 2;font-size: 14px;margin:0 auto 0;width: 355px;">
               	您的要求是我们研究和测试的基础，通过理论分析或试验测试，我们能够在一些您还不清楚的具体细节和您的疑问方面为你提供支持。研究和测试阶段，我们将开展如下工作：
          </span>
                    <span class="textHeader2"
                    >收集和测取基础数据</span
                    >
                    <span class="textHeader2"
                    >进行概念设计</span
                    >
                    <span class="textHeader2"
                    >开展实验验证和测试</span
                    >
                    <span class="textHeader2"
                    >完善概念设计并进行技术经济分析</span
                    >

                    <span class="textHeader3" style="margin-bottom: 10px;margin-top: 20px">研究的结果</span>
                    <span style="text-indent: 2em; line-height: 2;margin:0 auto 0;width: 355px;">
                        	研究和测试结果将形成一份概念设计文件，为你提供下一步决策所需要的关键信息，包括技术、预期的产品质量、能耗和投资估算等详细信息。概念设计文件通常包含下列信息：
          </span>

                    <span class="textHeader2"
                    >设计基础</span
                    >
                    <span class="textHeader2"
                    >工艺说明</span
                    >
                    <span class="textHeader2"
                    >工艺流程简图</span
                    >
                    <span class="textHeader2"
                    >主要工艺数据</span
                    >
                    <span class="textHeader2"
                    >主要耗能数据</span
                    >
                    <span class="textHeader2"
                    >关键设备清单</span
                    >
                    <span class="textHeader2" style="margin-bottom: 40px"
                    >投资估算</span
                    >
                </div>
            </template>

            <template v-if="selectIndex == 2">
                <div class="title1" style="height:42px;">
                    工程设计
                </div>
        <span style="text-indent: 2em; line-height: 2;margin-left: 6px;font-size: 14px">
                    	敏恒科技善于应用深厚的专业知识和丰富的实践经验，有效和务实的将客户复杂的需求转变为清晰的解决方案。作为客户的技术服务商和装备供应商，敏恒科技致力于成为合作伙伴工程项目建设所有阶段的顾问。我们的工程设计服务范围包括： 
          </span>
                <span class="textHeader2" style="margin-left: 32px"
                >基础工程设计</span
                >
                <span class="textHeader2" style="margin-left: 32px"
                >配合详细工程设计</span
                >
                <span class="textHeader2" style="margin-left: 32px"
                >提供安装、调试和试生产指导服务</span
                >

                <span class="textHeader3" style="margin-bottom: 10px;margin-top: 17px">基础工程设计</span>

                <span style="text-indent: 2em; line-height: 2;margin:0 auto 0;width: 355px;font-size: 14px">
                    	基础工程设计工艺将为一个新装置的建设提供必要的工艺技术信息，基础工程设计工艺的内容和深度将根据客户的具体要求确定。我们基础工程设计工艺的内容通常包括：
                 </span>
                <div class="imgboxs200">
                    <viewer>
                        <img src="@/assets/image/server/3.png" class="img2" style="width:355px;height:157px;margin: 10px auto 20px;border-radius: 5px;" />
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:90%;">
                </div>

            </template>

            <template v-if="selectIndex == 3">
                <div class="title1" style="height:42px;">
                    设备制造
                </div>
                <span style="text-indent: 2em; line-height: 2;margin-left: 10px; font-size: 14px;">
                    	我们的核心设备，是经验丰富的专家数十年经验积累和不断改进的结果，处于相关领域的前沿。经过专门设计和定制，可以实现设备性能和工艺要求的最佳匹配，最大程度的延长设备使用寿命，降低维护成本，提升您的利润。
          </span>

                <div class="imngbox2" style="margin-bottom: 10px;margin-top: 14px">
                    <div class="imgboxs200">
                        <viewer :images="images">
                            <img v-for="(src,index) in images" :src="src" v-show="index==0" style="width:117px;border-radius: 5px;"/>
                        </viewer>
                        <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:75%;">
                        <span class="imgText">激光焊机生产线</span>
                    </div>
                    <div class="imgboxs200">
                        <viewer :images="images">
                            <img v-for="(src,index) in images" :src="src" v-show="index==1"  style="width:117px;border-radius: 5px;"/>
                        </viewer>
                        <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:75%;">
                        <span class="imgText">渗透性着色试验 1.1</span>
                    </div>
                    <div class="imgboxs200">
                        <viewer :images="images">
                            <img v-for="(src,index) in images" :src="src" v-show="index==2"  style="width:117px;border-radius: 5px;"/>
                        </viewer>
                        <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:75%;">
                        <span class="imgText">渗透性着色试验 1.2</span>
                    </div>
                </div>

                <div class="imngbox4" style="margin-bottom: 10px">
                    <div class="imgboxs200">
                        <viewer :images="images">
                            <img v-for="(src,index) in images" :src="src" v-show="index==3" style="border-radius: 5px;"/>
                        </viewer>
                        <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:7px;left:82%;">
                        <span class="imgText" style="text-align: center;width:175px;"
                        >组装成品</span
                        >
                    </div>
                    <div class="imgboxs200">
                        <viewer :images="images">
                            <img v-for="(src,index) in images" :src="src" v-show="index==4" style="border-radius: 5px;"/>
                        </viewer>
                        <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:7px;left:82%;">
                        <span class="imgText" style="text-align: center;width:175px;"
                        >整体水压试验</span
                        >
                    </div>
                </div>
                <div class="title1" style="height:42px;">
                    系统集成
                </div>
                <span style="text-indent: 2em; line-height: 2;margin-left: 10px;font-size: 14px;margin-bottom: 16px">
         	我们的撬装装置或现场安装系统，由我们经验丰富的专家、经过验证的工作程序、以及现代化的加工和焊接设备生产。我们经过审核的分包商确保严格地遵守生产计划，高效及时的交付助您加速将产品推向市场，获取竞争优势。</span>
                <div class="imngbox4" style="margin-bottom: 20px">
                    <div class="imgboxs200">
                        <viewer :images="images">
                            <img v-for="(src,index) in images" :src="src" v-show="index==5" style="border-radius: 5px;"/>
                        </viewer>
                        <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:7px;left:82%;">
                        <span class="imgText" style="text-align: center;width:175px;"
                        >撬装车间</span
                        >
                    </div>
                    <div class="imgboxs200">
                        <viewer :images="images">
                            <img v-for="(src,index) in images" :src="src" v-show="index==6" style="border-radius: 5px;"/>
                        </viewer>
                        <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:7px;left:82%;">
                        <span class="imgText" style="text-align: center;width:175px;"
                        >设备制造车间</span
                        >
                    </div>
                </div>

                <div class="imngbox4" style="margin-bottom: 40px">
                    <div class="imgboxs200">
                        <viewer :images="images">
                            <img v-for="(src,index) in images" :src="src" v-show="index==7" style="border-radius: 5px;"/>
                        </viewer>
                        <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:7px;left:82%;">
                        <span class="imgText" style="text-align: center;width:175px;"
                        >脱水单元撬装产品</span
                        >
                    </div>
                    <div class="imgboxs200">
                        <viewer :images="images">
                            <img v-for="(src,index) in images" :src="src" v-show="index==8" style="border-radius: 5px;"/>
                        </viewer>
                        <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:7px;left:82%;">
                        <span class="imgText" style="text-align: center;width:175px;"
                        >分离单元撬装产品</span
                        >
                    </div>
                </div>
            </template>

            <template v-if="selectIndex == 4">
                <div class="title1" style="height:42px;">
                    交付及售后服务
                </div>
                <span class="textHeader2"
                >我们的售后服务由您的要求决定。</span
                >
                <span class="textHeader2"
                >我们经验丰富的调试工程师现场指导调试和过程操作优化，以及为客户进行员工培训，直至装置能够稳定良好的运行。</span
                >
                <span class="textHeader2"
                >如果突然发生故障，我们将尽最大努力在当天派遣有经验的服务工程师</span
                >
                <span class="textHeader2"
                >如果设备发生严重损坏，我们配套供应商可提供经验丰富且设备齐全的维修设施。维修工作队将立即进行合格的维修，以确保设备或装置尽快回到运行状态。</span
                >

                <div class="title1" style="margin-top: 20px;height:42px;">
                    我们的服务
                </div>

                <div class="img-boxs" style="border-radius: 5px;">
                    <viewer class="imgboxs200">
                      <img src="@/assets/image/server/15.png">
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:90%;">
                </div>
            </template>
        </div>

        <mobileFooter></mobileFooter>
    </div>
</template>

<script>
    import mobileFooter from '../../../components/mobile_footer';

    export default {
        name: "mobileServer",
        components:{mobileFooter},
        data() {
            return {
                wapShow:true,
                selectIndex: 1,
                lishow: false,
                pageIndex: 1,
                images:[
                    require('@/assets/image/server/5.png'),
                    require('@/assets/image/server/6.png'),
                    require('@/assets/image/server/7.png'),
                    require('@/assets/image/server/8.png'),
                    require('@/assets/image/server/9.png'),
                    require('@/assets/image/server/10.png'),
                    require('@/assets/image/server/11.png'),
                    require('@/assets/image/server/12.png'),
                    require('@/assets/image/server/13.png')
                ],
            };
        },
        mounted() {
            this.selectIndex=this.$route.query.index?this.$route.query.index:1;
            document.getElementsByTagName("title")[0].innerText = '服务流程_重庆敏恒科技有限公司';
        },
		methods:{
			lishowclick(num){
				this.selectIndex = num;
			},
		},
        watch:{
            '$route'(){
                this.selectIndex=this.$route.query.index?this.$route.query.index:1;
            }
        }
    };
</script>

<style scoped>
    /*@import "../../../assets/css/mobileTechnology.css";*/
    a{
        text-decoration: none;
    }
    .Technology-content .textHeader2::after {
        background-color: rgba(0, 121, 255, 1) !important;
    }

    .img-boxs{
       width:355px;
	   height:270px;
	   background: #EAF4FF;
	   margin:0 auto 20px;
	   position:relative
    }
    .img-boxs img{
	  position: absolute;
      width:345px;
	  height:260px;
	  left:5px;
	  top:5px;
    }
    nav {
        width: 355px;
        height: 36px;
        background: #E6E8EB;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        margin:0 auto;
    }
    .nav-box a:hover{
        cursor: pointer;
    }
    nav a{
        text-decoration: none;
    }
    nav a:hover{
        background:#0079FF;
        color:#fff;
    }
    nav a:hover .sec-bar{
        display: block;
    }
    nav a.router-link-exact-active {
        background: #0079FF;
        color: #FFFFFF;
    }
    nav .router-link-active .active1{
        background: #0079FF;
        color: #FFFFFF;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0px 0px;
    }
    nav .serverNav{
        width: 25%;
        height: 46px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        color: #000000;
        display: block;
        border-radius: 10px 10px 0px 0px;
        text-decoration: none;
        z-index: 9;
    }



    .erji-nav .router-link-exact-active span{
        cursor: pointer;
        color: #0079FF;
        padding-bottom:5px;
        border-bottom: 2px #0079ff solid;
    }

    .Technology-content {
        text-align: left;
        width:355px;
        margin:20px auto 0 auto;
    }
    .Technology-content .title1 {
        padding: 0 10px;
        width: fit-content;
        height: 55px;
        border-radius: 10px;
        border: 1px solid #0079ff;
        font-size: 20px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0079ff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .Technology-content span {
        display: block;
    }
    .Technology-content .text {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3a3a3a;
        display: block;
    }
    .Technology-content .textHeader2 {
        margin-left: 20px;
        margin-bottom: 8px;
        position: relative;
        padding-left: 20px;
        margin-top: 16px;
        font-size: 14px;
        letter-spacing: 1px;
    }
    .Technology-content .textHeader2::after {
        content: "";
        position: absolute;
        left: 4px;
        width: 6px;
        height: 6px;
        top: 8px;
        background: #6a6a6a;
    }
    .Technology-content .img2 {
        width: 355px;
        height: 82px;
        object-fit: cover;
        margin: 0 auto;
        display: block;
        position: relative;
    }
    .Technology-content .imgText {
        width: 115px;
        height: 34px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #363a44;
        text-align: center;
        margin-top:6px;
    }
    .Technology-content .imngbox img {
        width: 115px;
        height: 81px;
        object-fit: contain;
    }

    .Technology-content .imngbox1 img {
        width: 115px;
        height: 81px;
        object-fit: contain;
    }
    .Technology-content .imngbox2 {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .Technology-content .imngbox2 img {
        width: 115px;
        /* height: 81px; */
        object-fit: contain;
    }

    .Technology-content .imngbox4 {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .Technology-content .imngbox4 img {
        width:175px;
        /* height: 305px; */
        object-fit: contain;
    }

    /*.Technology-content .imngbox3 img {*/
    /*  width: 1084px;*/
    /*  height: 240px;*/
    /*  object-fit: cover;*/
    /*}*/
    .Technology-content .textHeader3 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        padding: 5px 20px;
        background: #0079FF;
        border-radius: 5px;
        width: fit-content;
        margin-left: 8px;
    }

    /*产品纯度高end*/
    .server-header .rights img{
        width:355px;
        margin:0 auto 0;
    }
    .imgboxs200{
        position: relative;
    }

    .serverM{
        width: 375px;
        background: #FFFFFF;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        margin:10px auto 0;
    }


</style>
