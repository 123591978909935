<template>
  <div class="technology2">
    <div class="Technology-headers">
		<div class="tech_nav">
			  <nav class="nav-box" style="height:46px;">
			     <router-link to="/technologys?index=1&wapShow=true" class="nav-bar" @click="lishowclick(1)" style="padding-top:9px;height:37px;" :class="{ select: selectIndex == 1 }">
		            锂电添加剂<br>系列产品
				 </router-link>
			     <router-link to="/technologys?index=2&wapShow=true" class="nav-bar"  style="padding-top:9px;height:37px;">
			       <span
				   :class="{ select: selectIndex == 2 }"
				   @click="lishowclick(2)"
				   >氰化及衍生物<br>系列产品</span>
			     </router-link>
			     <router-link to="/technologys?index=3&wapShow=true" class="nav-bar" style="padding-top:9px;height:37px;">
			       <span style="margin-top:7px;"
				   :class="{ select: selectIndex == 3}"  @click="lishowclick(3)"
				   >三废资源化<br>利用技术</span>
			     </router-link>
			   </nav>
		</div>
      <div class="right" style="margin-top:10px;">
        <template v-if="selectIndex == 1">
          <img src="@/assets/image/technology/19.png" class="img1" style="border-radius: 5px;"/>
        </template>

        <template v-if="selectIndex == 2">
          <img src="@/assets/image/technology/25.png" class="img1" style="border-radius: 5px;"/>
        </template>


        <template v-if="selectIndex == 3">
          <img src="@/assets/image/technology/26.png" class="img1" style="border-radius: 5px;"/>
        </template>
      </div>
    </div>

    <div class="Technology-content">
      <template v-if="selectIndex == 1">
        <div class="title1">
          锂电池添加剂系列产品工艺技术 —— 先进、成熟和可靠的生产工艺
        </div>

        <div class="text">
          <span style="text-indent: 2em; line-height: 2">
            我们拥有部分锂电池添加剂产品的生产工艺技术，可以为客户提供从工艺技术包到工程设计、采购、安装和调试的一体化服务。
<!--           我们拥有部分锂电池添加剂产品的生产工艺技术，可以为客户提供从工艺包到合格产品的一站式、一体化、全流程服务。-->
          </span
          ><br />
          <span class="textHeader3" style="margin-bottom: 15px"
            >氯代碳酸乙烯酯（CEC）工艺技术</span
          >
          <span style="margin:0 auto 0 auto;width:355px;text-align: justify;"
            >氯代碳酸乙烯酯（CEC）是生产锂电池添加剂碳酸亚乙烯酯（VC）的主要原料，由碳酸乙烯酯（EC）与氯气（Cl2）反应得到，化学反应式如下：</span
          >
          <div class="imngbox3" style="margin: 10px auto 0 auto;">
            <div style="margin: 0 auto" class="imgboxs200">
              <viewer :images="images">
                <img v-for="(src,index) in images" :src="src" v-show="index==0" style="width:355px;height:81px;border-radius: 5px;"/>
              </viewer>
              <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:90%;">
              <span class="imgText" style="width:355px;"
                >氯代碳酸乙烯酯（CEC）工艺技术化学反应式</span
              >
            </div>
          </div>
          <span
            style="
              text-indent: 2em;
              line-height: 2;
			  margin:0 auto 0 auto;
		      width:355px;
			  text-align: justify;
            "
          >
            当前，主流工艺为间歇批次光催化鼓泡反应，该工艺存在反应时间长（10~40h/批次）、氯气消耗高（理论0.58t/t，实际＞0.72t/t）、副产二氯含量高（通常＞6%）、催化光源能耗大（110~130kwh/t）等问题。针对当前主流工艺存在的问题，敏恒科技开发了连续光催化反应系统，该反应系统实现了连续合成，反应时间短（＜6h）、氯气消耗低（0.65t/t）、产品质量高（≥82%）、催化光源能耗低（8~10kwh/t）。
            连续光催化反应系统由液氯储存、汽化、合成、脱酸和尾气吸收等单元系统构成，敏恒科技已经完成全系统工艺开发，可以为合作伙伴提供从工艺包设计到生产调试的全流程服务。</span
          ><br />

          <span class="textHeader3" style="margin-bottom: 10px"
            >碳酸亚乙烯酯（VC）工艺技术</span
          >
          <span style=" margin:0 auto 10px auto;
			 width:355px;
			 text-align: justify;"
            >碳酸亚乙烯酯（VC）因其综合性能较好，是锂电池添加剂中用量最大的品种，该产品由氯代碳酸乙烯乙酯（CEC）脱氯化氢制备，化学反应式如下：</span
          >
          <div class="imgboxs200">
            <viewer :images="images">
              <img v-for="(src,index) in images" :src="src" class="img2" v-show="index==1" style="border-radius: 5px;"/>
            </viewer>
            <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:90%;">
          </div>

          <span class="imgText" style="width:355px;">碳酸亚乙烯酯（VC）工艺技术化学反应式</span>
          <span
            style="
              text-indent: 2em;
              line-height: 2;
              margin:0 auto 0 auto;
              width:355px;
              text-align: justify;
            "
          >
            该工艺反应简单，但脱氯化氢时产生大量的三乙胺盐酸盐，为了促进反应传质和有利于后续固液分离操作，需要加入大量溶剂降低固含量。大量溶剂的加入，降低了反应速率，增加了物料周转量，降低了生产效率，导致其生产成本较高。</span
          ><span
            style="
              text-indent: 2em;
              line-height: 2;
              margin:0 auto 0 auto;
              width:355px;
              text-align: justify;
            "
          >
            敏恒科技在现有工艺的基础上，对其反应动力学、热力学的进行了深入研究，并对体系物性进行了详细的测定，开发无溶剂连续合成、连续分离新工艺。该工艺由合成、固液分离洗涤、蒸馏、精馏、熔融结晶、三乙胺回收、三废处理等单元和系统组成，与传统工艺相比，在同等投资规模下，产能提升了3倍，收率提高了10%，生产综合成本下降20%，具有经济效益。</span
          >
          <span
            style="
              text-indent: 2em;
              line-height: 2;
              width:355px;
              text-align: justify;
              margin: 0 auto 0;
            "
            >敏恒科技愿意为行业进步贡献自己的一份力量，如您有需要，请<a href = "mailto:service@mhkjcq.com" style="color: #0079FF">联系我们</a>。</span
          >

          <span
            class="textHeader3"
            style="margin-bottom: 10px; margin-top: 10px"
            >氟代碳酸乙烯酯（FEC）工艺技术</span
          >
          <span
            style="
              line-height: 2;
              text-indent: 2em;
              width:355px;
              text-align: justify;
              margin: 0 auto 10px;
            "
            >氟代碳酸乙烯酯（FEC）是排在碳酸亚乙烯酯（VC）之后使用量处于第二位的锂电池添加剂，并有替代部分溶剂的潜力，该产品由氯代碳酸乙烯乙酯（CEC）进行氟代反应制备，化学反应式如下：</span
          >

          <div class="imgboxs200">
            <viewer :images="images">
              <img v-for="(src,index) in images" :src="src" class="img2" v-show="index==2" style="border-radius: 5px;"/>
            </viewer>
            <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:90%;">
            <span class="imgText" style="width:355px;">氟代碳酸乙烯酯（FEC）工艺技术化学反应式</span>
          </div>

          <span
            style="
              text-indent: 2em;
              line-height: 2;
              width:355px;
              text-align: justify;
              margin: 0 auto 0;
            "
          >
            以氟化钾为氟代反应试剂的工艺成熟可靠，但由于原料为固液两相，产品也为固液两相，氟代副产生成的氯化钾包裹在氟化钾外层，导致氟化钾难以实现充分利用。反应时，为了使氯代碳酸乙烯酯转化较为彻底，通常采用增加氟化钾用量的办法实现，这不仅导致氟化钾消耗增加，还增大了副产混合废盐的处理负荷，增加了产品生产成本。</span
          >

          <span
            style="
              text-indent: 2em;
              line-height: 2;
              width:355px;
              text-align: justify;
              margin: 0 auto 0;
            "
          >
            敏恒科技在深刻理解反应机理的基础上，开发了新型反应系统，成功的将氟化钾消耗由1.5~1.8当量降低至1.1~1.2当量，反应时间由5小时降低至2小时并实现了连续化
            合成和分离。新工艺产品收得率提高了5%~10%，综合成本降低了15%，具有显著的经济效益。新工艺将为我们的合作伙伴创造持久竞争优势，如您有需要，请联系我们。</span
          ><br />

          <span
            class="textHeader3"
            style="margin-bottom: 10px; margin-top: 10px"
            >硫酸乙烯酯（DTD）工艺技术</span
          >
          <span
            style="
              margin-left: 13px;
              margin-bottom: 16px;
              line-height: 2;
              text-indent: 2em;
		      width:355px;
			  margin: 0 auto 0;
			  text-align: justify;
            "
          >
            硫酸乙烯酯（DTD）作为锂电池电解液添加剂，在高能量密度的三元体系中性能突出，使用量稳步提升，预计到2025年，其需求量将达到3~4万吨/年。当前主流工艺以乙二醇和氯化亚砜为起始原料，首先合成亚硫酸乙烯酯（ES），再以三氯化钌（RuCl3）为催化剂，次氯酸钠为氧化剂，氧化亚硫酸乙烯酯合成硫酸乙烯酯（DTD），相关化学反应式如下：</span
          >

          <div class="imngbox1" style="margin-top: 18px;justify-content: space-around;width:355px">
            <div class="imgboxs200">
              <viewer :images="images" >
                <img v-for="(src,index) in images" style="width: 173px;border-radius: 5px;" :src="src" v-show="index==3" />
              </viewer>
              <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:80%;">
            </div>
            <div class="imgboxs200">
              <viewer :images="images">
                <img v-for="(src,index) in images" style="width: 173px;border-radius: 5px;" :src="src" v-show="index==4" />
              </viewer>
              <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:80%;">
            </div>
          </div>
          <span class="imgText" style="width:355px;">硫酸乙烯酯（DTD）工艺技术化学反应式</span>

          <span
            style="
              text-indent: 2em;
              line-height: 2;
              width:355px;
              margin: 0 auto 0;
              text-align: justify;
            "
          >
            以乙二醇和氯化亚砜为原料的生产工艺原料成本较低，但当前间歇批次的生产方式生产费用较高，例如亚硫酸乙烯酯生产时，将氯化亚砜滴加到乙二醇中，反应前期放热、需要及时移走热；后期吸热，需要给予热量。热量的移走和给予，都会引起公用工程投资增加，运行费用上升。</span
          >

          <span
            style="
              text-indent: 2em;
              line-height: 2;
              width:355px;
              margin: 0 auto 0;
              text-align: justify;
            "
          >
            敏恒科技的连续合成和连续提纯工艺，大幅简化了公用工程和操作。新的工艺由亚硫酸乙烯酯（ES）连续合成及分离提纯、硫酸乙烯酯（DTD）连续合成及分离提纯、硫酸乙烯酯干燥和包装等单元组成，过程全连续、物料全密闭。操作环境友好，产品质量稳定，综合收率比间歇批次工艺高10%，生产费用只有间歇批次的40%，优势明显，欢迎联系我们，展开合作。</span
          ><br />
        </div>
      </template>

      <template v-if="selectIndex == 2">
        <span class="title1" style="margin-bottom: 10px;height:85px;"
          >氰化产品及衍生物工艺技术——高效和安全的连续氰化物及衍生物生产工艺</span
        >
        <span style="text-indent: 2em; line-height: 2; font-size: 14px;width:355px;text-align:justify;margin:0 auto 0;">
          氢氰酸是一种化学性质非常活泼的化合物，与醛、酮、亚胺、双键、环氧等加成可以生成许多衍生物，这些衍生物多数是精细化工中间体，在医药、农药、染料、助剂和冶金等领域有重要用途，但由于这类产品市场需求总量不大，大部分生产过程为釜式间歇批次生产，生产效率低、安全性差、产品质量波动大。 </span
        ><span style="text-indent: 2em; line-height: 2; font-size: 14px;width:355px;text-align:justify;margin:0 auto 0;"
          >敏恒科技针对当前氰化反应存在的问题，开发了高效和安全的连续氰化反应工艺。该工艺以固体碱为催化剂在静态混合换热反应器中连续进行，物料停留时间短、产品质量稳定、全自动化运行，安全风险低。</span
        >

        <span class="textHeader3" style="margin-bottom: 20px; margin-top: 16px"
          >技术特点</span
        >
        <span class="textHeader2"
          >以固体碱进行催化，无中和废盐产生，环境友好，全流程密闭操作，安全性高；</span
        >
        <span class="textHeader2"
          >反应产生的热量被迅速带走，温度控制精准；</span
        >
        <span class="textHeader2"
          >物料停留时间短，减少了氢氰酸反应过程中的聚合，所得产品含量高，颜色好。</span
        >

        <span class="textHeader3" style="margin-bottom: 20px;margin-top: 26px"
          >部分示例产品</span
        >

        <span class="textHeader2"
          >以s-环氧氯丙烷为原料，与氢氰酸反应制备S-4-氯-3-羟基丁腈，再醇解制备S-4-氯-3-羟基丁酸乙酯</span
        >
        <span class="textHeader2"
          >以环氧乙烷为原料，与氢氰酸反应制备3-羟基丙腈，再加氢得到3-氨基丙醇</span
        >
        <span class="textHeader2"
          >以环己酮为原料，与氢氰酸反应制备出1-羟基环己基甲腈，再经水解酯化得到1-羟基环己基甲酸甲酯</span
        >
        <span class="textHeader2"
          >以环戊酮为原料与氢氰酸和氨反应制备得到1-氨基环戊基甲腈</span
        >
        <span class="textHeader2"
          >以对苯醌为原料与氢氰酸反应制备得到3，6-二羟基邻苯二甲腈，再经过氯氧化得到二氯二氰基苯醌（DDQ）</span
        >
        <span class="textHeader2"
          >以正丁醛为原料与氢氰酸反应制备得到2-羟基戊腈</span
        >
        <span class="textHeader2"
          >以异佛尔酮为原料与氢氰酸反应制备得到异佛尔酮腈</span
        >
        <span class="textHeader2" style="margin-bottom:60px"
          >以2，5-二氢呋喃为原料与氢氰酸反应制备得到3-氰基-四氢呋喃，再经过加氢得到3-甲氨基四氢呋喃</span
        >
      </template>



            <template v-if="selectIndex == 3">
        <span class="title1" style="margin-bottom: 10px;"
          >三废资源化利用技术——环境友好的生产工艺</span
        >
         <span style="margin-left:8px; line-height: 2; font-size: 14px;margin:0 auto 0;width:355px;text-align:justify;">
          我们在三废资源化领域实施了一些项目，取得了一些成果，很希望与合作伙伴一起，将三废尽可能资源化利用，将生产对环境的影响降到最低，为可持续发展贡献我们的力量。 </span
        >
               <span class="textHeader3" style="margin-bottom: 20px; margin-top: 16px"
          >电石渣制备高纯碳酸钙（CaCO3）工艺技术</span
        >
                <span style="text-indent: 2em; line-height: 2;font-size:14px;margin:0 auto 0;width:355px;text-align:justify;"
            >	电石渣是碳化钙水解生产乙炔气后的废渣，其中含有大量的氧化钙和少量的硅、铁、铝、钙、镁及碳渣。乙炔作为PVC、聚氯乙烯和氯丁橡胶的主要原料，需求巨大，目前我国的PVC产量已接近千万吨，所产生的电石渣达八百万吨左右。由于其成分较为复杂，并带有臭味，在很多地方对周边的环境造成了严重的环境污染，是我国清洁生产和资源循环利用的重点和难点。目前我国的电石渣只有很少量的用于混凝土和水泥浆，大部分的作为固体废渣，没有得到充分的利用，堆放的电石渣不但占用大量的土地，而且污染堆放场地附近的水资源，容易风干起飞灰，形成粉尘和大气污染，是必须重视和优先处理和利用的工业废弃物。
                </span> <span style="margin:0 auto 0;width:355px;text-align:justify;text-indent: 2em; line-height: 2;font-size:14px;"
            >敏恒科技在众多单位和个人探索研究的基础上，开发了电石渣制备高纯碳酸钙工艺技术，所生产的碳酸钙在饲料添加剂、医药、造纸和涂料等领域有广泛的应用，年需求量超百万吨。该工艺技术能够大规模消纳电石渣，同时减少常规碳酸钙生产对环境资源的占用，是真正的环境友好技术。</span
          >

                       <span class="textHeader3" style="margin-bottom: 20px; margin-top: 10px"
          >工艺原理</span
        >
            <span style="text-indent: 2em; line-height: 2;font-size:14px;margin:0 auto 10px;width:355px;text-align:justify;"
            >		利用氯化铵溶液循环工作液，将电石渣磨细后溶解于氯化铵溶液中，电石渣中的氢氧化钙与氯化铵反应生成为氯化钙和游离氨，过滤除去不溶性杂质，再加入除剂剂进行深度净化得到纯净的氯化钙氨水溶液，向其中通入二氧化碳，碳化结晶得到产品碳酸钙，过滤分离后母液NH4Cl溶液作为循环工作液返回溶解工序。相关化学反应式如下：
            </span>

            <span style="text-indent: 2em; line-height: 2;font-size:14px;margin:0 auto 10px;"
            >	Ca(OH)2+ 2NH4Cl →CaCl2+ 2NH3·H2O</span>
	<span style="text-indent: 2em; line-height: 2;font-size:14px;margin:0 auto 0;"
            >	CaC12+ 2NH3 + CO2 +H2O→ CaCO3 + 2NH4Cl
                </span>

                                 <span class="textHeader3" style="margin-bottom: 20px; margin-top: 16px"
          >工艺示意流程图</span
        >
              <div class="imgboxs200">
                <viewer :images="images1">
                  <img v-for="(src,index) in images1" :src="src" class="img2" v-show="index==0" style="border-radius: 5px;"/>
                </viewer>
                <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:90%;">
              </div>

          <span class="imgText" style="width:355px;">电石渣制备高纯碳酸钙（CaCO3）工艺示意流程图</span>

                                    <span class="textHeader3" style="margin-bottom: 20px; margin-top: 16px"
          >原料消耗</span
        >
                          <img src="@/assets/image/technology/28.png" class="img2" style="margin-bottom:40px;object-fit: contain" />
            </template>
    </div>

    <mobileFooter></mobileFooter>
  </div>
</template>

<script>
  import mobileFooter from '../../../components/mobile_footer';

export default {
  components:{mobileFooter},
  name: "mobileTechnologys",
  data() {
    return {
      wapShow:true,
      selectIndex: 1,
      images:[
        require('@/assets/image/technology/20.png'),
        require('@/assets/image/technology/21.png'),
        require('@/assets/image/technology/22.png'),
        require('@/assets/image/technology/23.png'),
        require('@/assets/image/technology/24.png')
      ],
      images1:[
        require('@/assets/image/technology/27.png')
      ],
    };
  },
  mounted() {
    this.selectIndex=this.$route.query.index?this.$route.query.index:1;
    document.getElementsByTagName("title")[0].innerText = '技术&解决方案_重庆敏恒科技有限公司';
  },
  methods:{
	  lishowclick(num){
		  this.selectIndex = num;
	  },
  },
  watch:{
    '$route'(){
      this.selectIndex=this.$route.query.index?this.$route.query.index:1
    }
  }
};
</script>

<style scoped>
  /*@import "../../../assets/css/mobileTechnology.css";*/
.Technology-content .textHeader2::after{
    background-color: rgba(0, 121, 255, 1) !important;
}

  nav {
    width: 355px;
    height: 36px;
    background: #E6E8EB;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    margin:0 auto;
  }

  nav .nav-bar {
    width: 34%;
    height: 46px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    color: #000000;
    display: block;
    border-radius: 10px 10px 0px 0px;
    text-decoration: none;
    z-index: 9;
  }
  .nav-box a:hover{
    cursor: pointer;
  }
  nav a{
    text-decoration: none;
  }
  nav a:hover{
    background:#0079FF;
    color:#fff;
  }
  nav a.router-link-exact-active {
    background: #0079FF;
    color: #FFFFFF;
  }
  nav .router-link-active .active1{
    background: #0079FF;
    color: #FFFFFF;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0px 0px;
  }
  .erji-nav .router-link-active{
    display: inline-block;
    width:146px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363A44;
    text-decoration: none;
  }

  .erji-nav .router-link-exact-active span{
    cursor: pointer;
    color: #0079FF;
    padding-bottom:5px;
    border-bottom: 2px #0079ff solid;
  }
  .Technology-header .rights .img1 {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .Technology-header .right .img1 {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .Technology-headers .right .img1 {
    width: 355px;
    height: 100%;
    object-fit: contain;
  }

  .Technology-content {
    text-align: left;
    width:355px;
    margin:20px auto 0 auto;
  }
  .Technology-content .title1 {
    padding: 0 10px;
    width: fit-content;
    height: 55px;
    border-radius: 10px;
    border: 1px solid #0079ff;
    font-size: 20px;
    letter-spacing: 1px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0079ff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .Technology-content span {
    display: block;
  }
  .Technology-content .text {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3a3a3a;
    display: block;
  }
  .Technology-content .textHeader::after {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    background-image: url(../../../assets/image/technology/0.png);
    background-size: 100% 100%;
    left: 0px;
    top: 4px;
  }
  .Technology-content .textHeader2 {
    margin-left: 20px;
    margin-bottom: 8px;
    position: relative;
    padding-left: 20px;
    margin-top: 16px;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .Technology-content .textHeader2::after {
    content: "";
    position: absolute;
    left: 4px;
    width: 6px;
    height: 6px;
    top: 8px;
    background: #6a6a6a;
  }
  .Technology-content .img2 {
    width: 355px;
    height: 82px;
    object-fit: cover;
    margin: 0 auto;
    display: block;
    position: relative;
  }
  .Technology-content .imgText {
    width: 115px;
    height: 34px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363a44;
    text-align: center;
    margin-top:6px;
  }
  .Technology-content .imngbox img {
    width: 115px;
    height: 81px;
    object-fit: contain;
  }
  .Technology-content .imngbox1 {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .Technology-content .imngbox1 img {
    width: 115px;
    height: 81px;
    object-fit: contain;
  }
  .Technology-content .imngbox2 img {
    width: 115px;
    /* height: 81px; */
    object-fit: contain;
  }

  .Technology-content .imngbox4 img {
    width:175px;
    /* height: 305px; */
    object-fit: contain;
  }

  .Technology-content .imngbox3 {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .Technology-content .textHeader3 {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    padding: 5px 20px;
    background: #0079FF;
    border-radius: 5px;
    width: fit-content;
    margin-left: 8px;
  }
  /*产品纯度高end*/
  .server-header .rights img{
    width:355px;
    margin:0 auto 0;
  }
  .imgboxs200{
    position: relative;
  }

  .technology2{
    width: 375px;
    background: #FFFFFF;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    margin:10px auto 0;
  }


</style>
