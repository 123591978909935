<template>
  <div class="tech-main">
    <div class="tech_nav">
		  <nav class="nav-box">
		     <router-link to="/technology?index=1&wapShow=true" class="nav-bar" style="padding-top:10px;height:26px;">
				<div style="height:28px;"  @click="lishowclick(1)">
					<span :class="{ select: selectIndex == 1||wapShow==true }">
						熔融结晶
					</span>
				</div>
			 </router-link>
		     <router-link to="/technology?index=3&wapShow=true" class="nav-bar" style="padding-top:10px;height:26px;">
		       <span
			   :class="{ select: selectIndex == 3 }"
			   @click="lishowclick(3)"
			   >升华结晶</span>
		     </router-link>
		     <router-link to="/technology?index=4&wapShow=true" class="nav-bar" style="padding-top:10px;height:26px;">
		       <span
			   :class="{ select: selectIndex == 4 }"  @click="lishowclick(4)"
			   >连续萃取</span>
		     </router-link>
		   </nav>
    </div>
	<div class="erji-nav" 	v-show="lishow">
		<router-link to="/technology?index=1&wapShow=true" class="text1">
		  <span   @click="lishowclick(1)">
			熔融结晶
		  </span>
		</router-link>
		<router-link to="/technology?index=2&wapShow=true" class="text2">
		  <span
		  @click="lishowclick(2)">
			设备与系统
		  </span>
		</router-link>
	</div>
    <div class="Technology-header">
      <div class="right">
        <template v-if="selectIndex == 1">
          <img src="@/assets/image/mobile/1.png" class="img1" />
		  <span class="imgText1">熔融结晶 —— <br>一种清洁、低能耗的高纯物质制备技术</span>
        </template>

        <template v-if="selectIndex == 2">
          <img src="@/assets/image/technology/6.png" class="img1" style="border-radius: 0 0 10px 10px;"/>
        </template>

        <template v-if="selectIndex == 3">
          <img src="@/assets/image/technology/12.png" class="img1" style="border-radius: 0 0 10px 10px;"/>
        </template>

		<template v-if="selectIndex == 4">
          <img src="@/assets/image/technology/16.png" class="img1" style="border-radius: 0 0 10px 10px;"/>
        </template>
      </div>
    </div>

    <div class="Technology-content">
      <template v-if="selectIndex == 1">
        <div class="text">
          <span style="text-indent: 2em; line-height: 2">
              敏恒科技开发和设计熔融结晶工艺技术，可提供量身定制的单体设备、集成橇装装置或现场安装系统。
          <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;熔融结晶是一种根据待分离物质之间凝固点不同而实现物质分离提纯的方法，是一种低能耗的有机物清洁分离工艺，对沸点接近的烃类、同分异构体和热敏材料等特殊物系分离效率高，超纯物质的制备是熔融结晶的特有优势。
          </span><br />

<!--熔融结晶特点-->
          <span class="textHeader">熔融结晶特点</span>
			<div class="wrap11" >
				<div class="mod6">
					<div class="mod7">
						<span class="word66">操作温度低：</span>
						<span class="word6733" >一般是常压、低温操作，低温下物料不聚合、不结焦碳化，分离收得率高，操作简单安全。</span>
					</div>
					<div class="mod8" style="margin-top:18px;">
						<img
							class="img5"
							referrerpolicy="no-referrer"
							src="@/assets/image/technology/29.png"
						/>
					</div>
				</div>
			</div>
			<div class="wrap112">
				<div class="mod6">
					<div class="mod7">
						<span class="word66">无溶剂：</span>
						<span class="word6744"
						>熔融结晶过程不需外加溶剂，既避免了溶剂对产品的污染，又减少了溶剂回收操作过程。</span
						>
					</div>
					<div class="mod8" style="margin-top:18px;">
						<img
							class="img5"
							referrerpolicy="no-referrer"
							src="@/assets/image/technology/30.png"
						/>
					</div>
				</div>
			</div>
			<div class="box8">
				<div class="section38 ">
					<span class="info27">能耗低：</span>
					<span class="word70"
					>与精馏相比，熔融热仅是汽化热的1/7~1/3。若再计入精馏的回流比与热损失，熔融结晶方法能耗仅为精馏能耗的10%~30%。&nbsp;对特种物系分离效率高:对于沸点相近物料、同分异构体、手性物质等常规精馏极难分离物系、热敏物质、稀溶液和高沸点物质具有极佳的分离效率和收率。</span
					>
					<div class="outer15 flex-col">
						<img
							class="img7"
							referrerpolicy="no-referrer"
							src="@/assets/image/technology/31.png"
						/>
					</div>
				</div>
			</div>
			<div class="wrap12">
				<div class="mod6s">
					<div class="mod7">
						<span class="word66">应用范围广：</span>
						<span class="word67s"
						>熔点在-10~200°C之间的许多有机物均可采用熔融结晶技术进行分离，超过50%的有机物熔点在0-200°C范围内，采用熔融结晶进行分离提纯是可行的工艺选择。</span
						>
					</div>
					<div class="mod8s">
						<img
							class="img5"
							referrerpolicy="no-referrer"
							src="@/assets/image/technology/32.png"
						/>
					</div>
				</div>
			</div>
			<div class="wrap11" style="height:119px;">
				<div class="mod6">
					<div class="mod7">
						<span class="word66">产品纯度高：</span>
						<span class="word67"
						>熔融结晶产品纯度一般可达到99.9%以上。</span
						>
					</div>
					<div class="mod8">
						<img
							class="img5"
							referrerpolicy="no-referrer"
							src="@/assets/image/technology/33.png"
						/>
					</div>
				</div>
			</div>

<!--熔融结晶特点end-->

          <span class="textHeader" style="margin-top: 20px">熔融结晶工艺原理</span>
          <span style="text-indent: 2em; line-height: 2;width: 355px; margin:0 auto;">
            熔融结晶通过逐步降低初始液态混合物的温度，让部分组分结晶析出，析出的固体相具有与残液不同的化学组成，从而达到分离提纯的目的。熔融结晶通过降温结晶、升温发汗和加热熔融三个过程的操作得到高纯产品。</span
          ><br />
            <div class="imgboxs200">
                <viewer :images="images">
                 <img v-for="(src,index) in images" :src="src" class="img2"  v-show="index==0" style="border-radius: 5px;"/>
                </viewer>
                <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;top:10px;left:90%;">
            </div>
          <span style="text-align: center;margin-top:6px;">熔融结晶工艺原理图</span>

          <span class="textHeader" style="margin-top: 20px"
            >熔融结晶的类型与流程</span
          >

          <span style="text-indent: 2em; line-height: 2; width: 355px;margin:0 auto;">
            按照操作模式和设备的不同，可以将熔融结晶分为层结晶、悬浮结晶和区域熔炼。层结晶中晶体层从壁面向结晶器内部生长，悬浮结晶中晶体在熔体中三维生长，区域熔炼主要应用于金属的提纯。根据传热和传质的推动方式不同可将层结晶分为静态层结晶和动态层结晶，两者的区别是静态操作由自然对流推动，而动态操作是由外加泵或搅拌引起的强制对流推动。三种熔融结晶方式各有优缺点，采用哪种结晶方式需根据物质体系、生产规模、操作习惯等具体信息进行选择。</span
          ><br />
          <div class="imngbox" style="width: 355px;margin:0 auto;">
            <div>
                <div class="imgboxs200">
                    <viewer :images="images">
                     <img v-for="(src,index) in images" :src="src" v-show="index==1" style="border-radius: 5px;"/>
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:74%;">
                </div>
              <span class="imgText">静态板式熔融结晶流程示意图</span>
            </div>
            <div>
                <div class="imgboxs200">
                    <viewer :images="images">
                     <img v-for="(src,index) in images" :src="src" v-show="index==2" style="border-radius: 5px;"/>
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:74%;">
                </div>
              <span class="imgText">动态降膜熔融结晶流程示意图</span>
            </div>
            <div>
                <div class="imgboxs200">
                    <viewer :images="images">
                     <img v-for="(src,index) in images" :src="src" v-show="index==3" style="border-radius: 5px;"/>
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:74%;">
                </div>
              <span class="imgText">悬浮熔融结晶流程示意图</span>
            </div>
          </div>

          <span class="textHeader" style="margin-top: 20px">典型应用</span>

          <span style="margin-left: 20px; margin-top: 20px"
            >熔融结晶特别适用于高纯物质的制备、热敏及沸点相近物料的分离，如下所示：</span
          >
          <span class="textHeader2"
            >将精馏到95%纯度的碳酸亚乙烯酯(VC)提纯到99.99%</span
          >
          <span class="textHeader2"
            >将99%的氟代碳酸乙烯酯(FEC)提纯到99.95%</span
          >
          <span class="textHeader2"
            >从99.5%的工业级1.3-丙烷磺酸内酯(1,3-PS)制备出纯度为99.99%的电子级产品</span
          >
          <span class="textHeader2"
            >将丙烯酸中阻聚剂含量从200ppm降低至20ppm</span
          >
          <span class="textHeader2">分离对甲酚/间甲酚的混合物</span>
        </div>

        <a class="btns" href="/static/敏恒科技熔融结晶提纯应用产品列表.pdf" download="敏恒科技熔融结晶提纯应用产品列表.pdf">更多应用></a>
<!--        <a class="btns" @click="open()">更多应用></a>-->

		<img src="../../../assets/image/mobile/bgimg.png" alt="" style="width:355px;height:158px;margin:10px auto 20px auto;">
      </template>

      <template v-if="selectIndex == 2">
        <div class="title1" style="height:40px;">设备与系统</div>

        <div class="text">
          <span style="line-height: 2; margin-left: 6px"
            >敏恒科技设计、制造和供应板式结晶器及完整系统模块。</span
          ><br />
          <span class="textHeader">我们的供货范围包括：</span>
          <span class="textHeader2">咨询/可行性研究</span>
          <span class="textHeader2">实验室测试</span>
          <span class="textHeader2">工艺设计</span>

          <span class="textHeader2">采购/制造/交货</span>
          <span class="textHeader2">安装</span>
          <span class="textHeader2">调试</span>
          <div class="title1" style="margin-top: 25px">
            板式熔融结晶器 —— 特别适用于精细化学品生产的熔融结晶器
          </div>
          <div style="font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;text-align: justify;
color: #3A3A3A;margin:0 auto 14px auto">以箱体、结晶板和支撑板组成板式熔融结晶器，无传动部件、无物料循环，静态间歇批次操作，免维护、易操作，可轻松胜任精细化学品的高标准分离要求。</div>
          <div class="imngbox" style="margin-bottom: 10px;width: 355px;margin:0 auto 0 auto;">
            <div>
                <div class="imgboxs200">
                    <viewer :images="images2">
                     <img v-for="(src,index) in images2" style="height: 81px;border-radius: 5px;" :src="src" v-show="index==0" />
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:75%;">
                </div>
              <span class="imgText">板式熔融结晶器外形</span>
            </div>
            <div>
                <div class="imgboxs200">
                    <viewer :images="images2">
                     <img v-for="(src,index) in images2" style="height: 81px;border-radius: 5px;" :src="src" v-show="index==1" />
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:75%;">
                </div>
              <span class="imgText">板式熔融结晶器结晶板</span>
            </div>
            <div>
                <div class="imgboxs200">
                    <viewer :images="images2">
                     <img v-for="(src,index) in images2" style="height: 81px;border-radius: 5px;" :src="src" v-show="index==2" />
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:75%;">
                </div>
              <span class="imgText">板式熔融结晶器支撑板</span>
            </div>
          </div>
          <span class="textHeader">优点</span>
          <span style="margin-left: 20px; margin-top: 20px"
            >板式熔融结晶器的优点显而易见：</span
          >
          <span class="textHeader2"
            >降膜式结晶器换热面积与重量比约为10~20㎡/吨，板式结晶器换热面积与重量比约为40~50㎡/吨，是降膜式结晶器的2.5~4倍。</span
          >
          <span class="textHeader2"
            >板式熔融结晶器为箱板式结构，空间利用率高，安装容易。</span
          >
          <span class="textHeader2"
            >换热板内部为波面枕型流道，特殊点阵设计，使其具有极高的传热效率和均匀的温度场分布。</span
          >
          <span class="textHeader2"
            >分离效率高，相同质量要求下，单程收得率比列管式高10~30%。</span
          >
          <div class="title1" style="margin-top: 25px;height:40px;">
            撬装集成装置或现场安装系统
          </div>
          <span style="text-indent: 2em; line-height: 2;margin:0 auto 0 auto; text-align: justify;">
           	敏恒科技为客户提供撬装集成装置或现场安装系统。 撬装集成装置将在我们或我们的合作伙伴车间完成组装，包括管道、接线和测试，然后分拆运输到客户现场进行二次组装和测试；现场安装系统将在客户现场完成组装和测试。 撬装集成装置或现场安装系统服务将最大限度地的节省客户投入项目的人力资源和精力，以便客户将其相关资源用在最能产生效益的地方。</span
          ><br />

          <div class="imngbox1" style="margin:0 auto 25px auto;width:355px;">
            <div>
                <div class="imgboxs200">
                    <viewer :images="images2">
                     <img v-for="(src,index) in images2" :src="src" v-show="index==3" style="width:172px;border-radius: 5px;" />
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:80%;">
                </div>
              <span class="imgText" style="margin:6px auto 0 auto;width:165px"
                >600吨/年丙烯酸熔融结晶去阻聚剂撬装系统</span
              >
            </div>
            <div>
                <div class="imgboxs200">
                    <viewer :images="images2">
                     <img v-for="(src,index) in images2" :src="src"  v-show="index==4" style="width:172px;border-radius: 5px;"/>
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:80%;">
                </div>
              <span class="imgText" style="margin:6px auto 0 auto;width:165px"
                >1000吨/年碳酸亚乙烯酯（VC）熔融结晶提纯现场安装系统</span
              >
            </div>
          </div>
        </div>
      </template>


      <template v-if="selectIndex == 3">
        <div class="title1">升华结晶 —— 利用物质升华特性进行分离提纯的技术</div>

        <div class="text">
          <span style="line-height: 2;text-indent: 2em; margin:0 auto 0 auto;width: 355px;text-align: justify;"
            >	利用物质的升华特性进行升华结晶分离，可从得到纯度很高的产品。固态状态下有足够高的蒸气压、对热稳定的固体物质都可以采用升华结晶技术进行分离提纯。升华结晶作为一种化工分离与精制的工业化生产方法，与精馏、萃取等化工单元操作相比，应用极少，究其原因主要是在工业化生产中没有可供选择的成型工业化升华设备，缺乏工业化生产的操作参数。
          </span><span style="line-height: 2;text-indent: 2em; margin:0 auto 0 auto;text-align: justify;">
            敏恒科技对升华结晶分离过程进行了数年的深入研究和开发，可为客户提供从工艺开发到成套集成装置的一体化服务。
        </span
          ><br />
          <span class="textHeader">工艺原理</span>

           <span style="line-height: 2;text-indent: 2em; margin:0 auto 0 auto;width: 355px;text-align: justify;"
            >		升华结晶是指固态物质不经过液态直接转变为气态及气态物质不经过液态直接变为固态的过程，其包含固体升华和气体凝华两个过程。当待分离固态物系中各组分在气相中分压差存在显著差别，且各组分对热相对稳定，加热待分离固态物系，易升华组分变为气态从固态中挥发出来，冷却挥发出来的气体，使其凝华，即可得到易升华组分的提纯晶体。
          </span>

                    <span class="textHeader" style="margin-top:10px">特点</span>

          <span style="margin-left: 20px; margin-top: 20px"
            >升华结晶技术具有如下的特点：</span
          >
          <span class="textHeader2">不使用化学溶剂，不产生废液，完全符合绿色化学的要求；</span>
          <span class="textHeader2">产品纯度高，纯度可高达99.9999%；</span>
          <span class="textHeader2">可得到晶型、粒度和外观特殊的产品。</span>

            <span class="textHeader" style="margin-top:30px">升华结晶的类型与流程</span>
   <span style="line-height: 2;text-indent: 2em;margin:0 auto 10px auto;width: 355px;text-align: justify;"
            >			升华结晶时，为了使升华持续进行，升华组分的蒸汽压须大于与固体接触的气相中该组分的分压，极少数的物质在加热的条件下能够在常压下升华；大部分的物质即便在加热条件，任需通过控制升华环境中的蒸汽分压才能实现升华，控制升华环境中的蒸汽分压可以借助真空或载气实现。因此根据操作条件的不同，可以将升华结晶分为常压升华、真空升华和载气升华三种类型。
          </span>
          <div class="imngbox" style="margin:0 auto 15px auto;width: 355px;">
            <div>
                <div class="imgboxs200">
                    <viewer :images="images1">
                     <img v-for="(src,index) in images1" :src="src" v-show="index==0" style="border-radius: 5px;"/>
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:75%;">
                </div>
              <span class="imgText">常压升华流程示意图</span>
            </div>
            <div>
                <div class="imgboxs200">
                    <viewer :images="images1">
                     <img v-for="(src,index) in images1" :src="src"  v-show="index==1" style="border-radius: 5px;"/>
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:75%;">
                </div>
              <span class="imgText">真空升华流程示意图</span>
            </div>
            <div>
                <div class="imgboxs200">
                    <viewer :images="images1">
                     <img v-for="(src,index) in images1" :src="src" v-show="index==2" style="border-radius: 5px;"/>
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:5px;left:75%;">
                </div>
              <span class="imgText">载气升华流程示意图</span>
            </div>
          </div>
          <span class="textHeader">典型应用</span>
          <span style="margin-left: 20px; margin-top: 20px"
            >升华结晶应用广泛，在如下示例中得到了成功应用：</span
          >
          <span class="textHeader2"
            >从含碘废液中回收单质碘</span
          >
          <span class="textHeader2"
            >将粗硫升华结晶制备用于轮胎、胶管和胶板等升华硫</span
          >
          <span class="textHeader2"
            >将粗萘经过升华提纯得到98.5%以上纯度的精萘</span
          >
          <span class="textHeader2"
            >升华提纯粗樟脑至99.5%以上纯度</span
          >
                 <span class="textHeader2"
            >精制提纯金属卤化物如三氯化铁（FeCl3）、三氯化铝（AlCl3）、单双碘二茂铁等产品</span
          >
                 <span class="textHeader2"
            >精制提纯有机光电材料BCP、Alq3、Bpy-OXD、B5T、BP2T等产品</span
          >
                 <span class="textHeader2"
            >分离回收或精制提纯水杨酸、对苯醌、苯甲酸、对苯二甲酸等产品</span
          >
        <a class="btns"  @click="open()">更多应用></a>
        </div>
      </template>

      <template v-if="selectIndex == 4">
        <div class="title1" style="height:40px;">连续萃取</div>

        <div class="text">
          <span style="line-height: 2;text-indent: 2em; margin:0 auto 0 auto;width:355px;text-align: justify;"
            >		萃取是利用物质在两种互不相溶（或微溶）的溶剂中溶解度或分配系数的不同，使溶质从一种溶剂内转移到另外一种溶剂中的方法。萃取分离是化学品生产中的重要单元操作，大规模化生产的化学品基本都实现了连续操作，但对于生产规模有限的众多的精细化学品，大部分还在使用釜式间歇操作，生产效率低、产品质量不高。
          </span><span style="line-height: 2;text-indent: 2em; margin:0 auto 0 auto;width:355px;text-align: justify;">
            敏恒科技可为客户定制开发分级连续萃取工艺和微分连续萃取工艺，提供定制化的萃取设备、橇装集成装置或现场安装系统。
        </span
          >
<!--          <span class="textHeader">工艺原理</span>-->

<!--           <span style="line-height: 2;text-indent: 2em; margin-left: 6px"-->
<!--            >		升华结晶是指固态物质不经过液态直接转变为气态及气态物质不经过液态直接变为固态的过程，其包含固体升华和气体凝华两个过程。当待分离固态物系中各组分在气相中分压差存在显著差别，且各组分对热相对稳定，加热待分离固态物系，易升华组分变为气态从固态中挥发出来，冷却挥发出来的气体，使其凝华，即可得到易升华组分的提纯晶体。-->
<!--          </span>-->

                    <span class="textHeader" style="margin-top:20px">优点</span>

          <span style="margin-left: 20px; margin-top: 20px"
            >与釜式间歇批次萃取相比，连续萃取具有如下的优点：</span
          >
          <span class="textHeader2">处理能力大；</span>
          <span class="textHeader2">选择性好；</span>
          <span class="textHeader2">能耗低；</span>
             <span class="textHeader2">回收率高；</span>
                <span class="textHeader2">易于自动控制，操作方便。</span>

            <span class="textHeader" style="margin-top:30px;">连续萃取的类型与流程</span>
   <span style="line-height: 2;margin:0 auto 10px auto;width:355px;text-align: justify;"
            >			常用的连续萃取根据使用的设备分为逐级萃取和微分萃取<br/>
逐级萃取： 以多级混合澄清槽为萃取设备的连续萃取过程，特点是每一个萃取级构成一个平衡级， 易实现过程分解、组合与控制。   <br/>
微分萃取过程：以各种塔为萃取设备的连续萃取过程，特点是设备紧凑，操作简单，结构形式选择多；但易出现轴向返混，影响萃取效率。<br/>
          </span>
          <div class="imngbox1" style="margin-bottom: 20px">
            <div>
                <div class="imgboxs200">
                    <viewer :images="images3">
                     <img v-for="(src,index) in images3" :src="src" v-show="index==0" style="width:173px;border-radius: 5px;" />
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:80%;">
                </div>
              <span class="imgText" style="width:173px;">分级连续萃取流程示意图</span>
            </div>
            <div>
                <div class="imgboxs200">
                    <viewer :images="images3">
                     <img v-for="(src,index) in images3" :src="src" v-show="index==1" style="width:173px;border-radius: 5px;" />
                    </viewer>
                    <img src="../../../assets/image/big.png" alt="" style="position: absolute;z-index:99;width:24px;height:24px;top:10px;left:80%;">
                </div>
              <span class="imgText" style="width:173px;">微分连续萃取流程示意图</span>
            </div>
          </div>
          <span class="textHeader">典型应用</span>
          <span style="margin-left: 20px; margin-top: 20px"
            >连续萃取技术在精细化学品生产中已经有广泛成功应用，如下所示：</span
          >
          <span class="textHeader2"
            >以甲苯为萃取剂从S构型环氧氯丙烷与氢氰酸水溶液反应后的体系中萃取分离S-4-氯-3-羟基丁腈</span
          >
          <span class="textHeader2"
            >以乙酸乙酯为萃取剂从盐水中萃取分离R-4-氰基-3-羟基丁酸乙酯</span
          >
          <span class="textHeader2"
            >以二氯乙烷为萃取剂从废水中回收亚硫酸乙烯酯</span
          >
          <span class="textHeader2"
            >以水萃取剂分离去除亚硫酸乙烯酯中的乙二醇</span
          >
                 <span class="textHeader2"
            >正丙醇从亚硫酸纸浆废水中提取香兰素</span
          >
                 <span class="textHeader2"
            >以丙酮-氯化钠双水相体系萃取分离对羟基苯乙胺</span
          >
                 <span class="textHeader2"
            >从氧乐果合成废水中萃取回收氧乐果</span
          >
                     <span class="textHeader2"
            >从精胺废水中萃取回收精胺</span
          >
        <a class="btns"  @click="open()">更多应用></a>
        </div>
      </template>
    </div>
      <div class="model-box" v-show="modelShow">
          <div class="model-bg" @click="modelShow=false"></div>
          <div class="model-content">
              联系我们获取更多资料<br/>
              咨询电话：023-67665596<br/>
              邮箱：service@mhkjcq.com<br/>
              <div class="btn1" @click="modelShow=false">确定</div>
          </div>
      </div>

      <mobileFooter></mobileFooter>
  </div>
</template>

<script>
import mobileFooter from '../../../components/mobile_footer';

export default {
  name: "mobileTechnology",
  components:{mobileFooter},
  data() {
    return {
      selectIndex: 1,
      wapShow:true,
      lishow: true,
      pageIndex:1,
      images:[
          require('@/assets/image/technology/2.png'),
          require('@/assets/image/technology/3.png'),
          require('@/assets/image/technology/4.png'),
          require('@/assets/image/technology/5.png')
      ],
        images1:[
            require('@/assets/image/technology/13.png'),
            require('@/assets/image/technology/14.png'),
            require('@/assets/image/technology/15.png'),
        ],
        images2:[
            require('@/assets/image/technology/9.png'),
            require('@/assets/image/technology/10.png'),
            require('@/assets/image/technology/11.png'),
            require('@/assets/image/technology/7.png'),
            require('@/assets/image/technology/8.png'),
        ],
        images3:[
            require('@/assets/image/technology/17.png'),
            require('@/assets/image/technology/18.png'),
        ],
        modelShow:false
    };
  },
    methods:{
        open(){
            this.modelShow=true
        },
		lishowclick(num){
			this.selectIndex = num;
			if(num==1||num==2){
				this.lishow = true;
			}else{
				this.lishow = false
			}
		},
    },
    mounted() {
        this.selectIndex=this.$route.query.index?this.$route.query.index:1;
        document.getElementsByTagName("title")[0].innerText = '技术&解决方案_重庆敏恒科技有限公司';
        if(this.selectIndex==1||this.selectIndex==2){
            this.lishow = true;
        }else{
            this.lishow = false
        }
    },
    watch:{
        '$route'(){
            this.selectIndex=this.$route.query.index?this.$route.query.index:1
        }
    },
};
</script>

<style scoped>
    /*@import "../../../assets/css/mobileTechnology.css";*/

    .tech-main{
        width: 375px;
        background: #FFFFFF;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        margin:10px auto 0;
    }
    nav {
        width: 355px;
        height: 36px;
        background: #E6E8EB;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        margin:0 auto;
    }

    nav .nav-bar {
        width: 34%;
        height: 46px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        color: #000000;
        display: block;
        border-radius: 10px 10px 0px 0px;
        text-decoration: none;
        z-index: 9;
    }
    .nav-box a:hover{
        cursor: pointer;
    }
    nav a{
        text-decoration: none;
    }
    nav a:hover{
        background:#0079FF;
        color:#fff;
    }
    nav a:hover .sec-bar{
        display: block;
    }
    nav a.router-link-exact-active {
        background: #0079FF;
        color: #FFFFFF;
    }
    nav .router-link-active .active1{
        background: #0079FF;
        color: #FFFFFF;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0px 0px;
    }
    .erji-nav{
        width:292px;
        height:30px;
        margin:10px auto 0 auto;
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .erji-nav .router-link-active{
        display: inline-block;
        width:146px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #363A44;
        text-decoration: none;
    }

    .erji-nav .router-link-exact-active span{
        cursor: pointer;
        color: #0079FF;
        padding-bottom:5px;
        border-bottom: 2px #0079ff solid;
    }
    .Technology-header {
        margin-top: 10px;
    }
    .Technology-header .right {
        width: 355px;
        height: 84px;
        margin:0 auto;
        position: relative;
        /* border-radius: 0px 0px 10px 10px; */
    }
    .Technology-header .right .imgText1{
        position: absolute;
        width: 255px;
        height: 40px;
        text-align: left;
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 20px;
        z-index:999;
        top:22px;
        left:50px;
    }
    .Technology-header .rights .img1 {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .Technology-header .right .img1 {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .Technology-headers .right .img1 {
        width: 355px;
        height: 100%;
        object-fit: contain;
    }

    .Technology-content {
        text-align: left;
        width:355px;
        margin:20px auto 0 auto;
    }
    .Technology-content .title1 {
        padding: 0 10px;
        width: fit-content;
        height: 55px;
        border-radius: 10px;
        border: 1px solid #0079ff;
        font-size: 20px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0079ff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .Technology-content span {
        display: block;
    }
    .Technology-content .text {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3a3a3a;
        display: block;
    }
    .Technology-content .textHeader {
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #363a44;
        margin-bottom: 14px;
        display: block;
        position: relative;
        padding-left: 20px;
    }
    .Technology-content .textHeader::after {
        content: "";
        width: 14px;
        height: 14px;
        position: absolute;
        background-image: url(../../../assets/image/technology/0.png);
        background-size: 100% 100%;
        left: 0px;
        top: 4px;
    }
    .Technology-content .textHeader2 {
        margin-left: 20px;
        margin-bottom: 8px;
        position: relative;
        padding-left: 20px;
        margin-top: 16px;
        font-size: 14px;
        letter-spacing: 1px;
    }
    .Technology-content .textHeader2::after {
        content: "";
        position: absolute;
        left: 4px;
        width: 6px;
        height: 6px;
        top: 8px;
        background: #6a6a6a;
    }
    .Technology-content .img2 {
        width: 355px;
        height: 82px;
        object-fit: cover;
        margin: 0 auto;
        display: block;
        position: relative;
    }
    .Technology-content .imgText {
        width: 115px;
        height: 34px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #363a44;
        text-align: center;
        margin-top:6px;
    }
    .Technology-content .imngbox {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .Technology-content .imngbox img {
        width: 115px;
        height: 81px;
        object-fit: contain;
    }
    .Technology-content .imngbox1 {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .Technology-content .imngbox1 img {
        width: 115px;
        height: 81px;
        object-fit: contain;
    }
    .Technology-content .imngbox2 img {
        width: 115px;
        /* height: 81px; */
        object-fit: contain;
    }
    .Technology-content .imngbox4 img {
        width:175px;
        /* height: 305px; */
        object-fit: contain;
    }
    .Technology-content .btns {
        width: 112px;
        height: 38px;
        background: #0079ff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin-top: 30px;
        margin-left: 20px;
        margin-bottom:30px;
    }
    .model-box{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }
    .model-box .model-bg{
        background-color: #00000070;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .model-box .model-content{
        width: 300px;
        height: 200px;
        border-radius: 10px;
        background-color: #ffffff;
        position: relative;
        z-index: 99;
        line-height: 1.6;
        padding-top: 40px;
        box-sizing: border-box;
        font-size: 16px;
    }
    .model-box .model-content .btn1{
        width: 80px;
        height: 40px;
        background-color: #0079ff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #ffffff;
        border-radius: 6px;
        margin:  20px auto 0;
    }

    /*熔融结晶特点*/

    .flex-col {
        display: flex;
        flex-direction: column;
    }

    /*操作温度低*/
    .wrap11 {
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
        width:355px;
        height:135px;
        margin:0 auto 10px auto;
    }
    .wrap112 {
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
        width:355px;
        height:131px;
        margin:0 auto 10px auto;
    }

    .mod6 {
        width: 315px;
        height: 128px;
        margin:0 auto;
    }
    .mod6s{
        width: 315px;
        height: 162px;
        margin:0 auto;
    }

    .mod7 {
        width: 119px;
        height: 128px;
        margin: 15px 12px 0 0;
        display: inline-block;
    }

    .word66 {
        width: 96px;
        height: 26px;
        display: block;
        overflow-wrap: break-word;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        white-space: nowrap;
        line-height: 26px;
        text-align: left;
        font-weight: 500;
        color: #3A3A3A;
    }

    .word67 {
        width: 179px;
        height: 50px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 13px;
        line-height: 20px;
        text-align: left;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
    }
    .word6733{
        width: 179px;
        height: 80px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 13px;
        line-height: 20px;
        text-align: left;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
    }
    .word6744{
        width: 179px;
        height: 72px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 13px;
        line-height: 20px;
        text-align: left;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
    }
    .word67s{
        width: 179px;
        height: 130px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 13px;
        line-height: 20px;
        text-align: left;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
    }
    .mod8 {
        z-index: 163;
        position: relative;
        width: 124px;
        height: 90px;
        border-radius: 5px;
        overflow: hidden;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16856d2a4b391a5689d0491daf3be0ccb51e0c4f13fb91bf8b77c3dde4b064c0)
        100% no-repeat;
        margin-top: 15px;
        float:right;
        display: inline-block;
    }
    .mod8s{
        z-index: 163;
        position: relative;
        width: 124px;
        height: 90px;
        border-radius: 5px;
        overflow: hidden;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16856d2a4b391a5689d0491daf3be0ccb51e0c4f13fb91bf8b77c3dde4b064c0)
        100% no-repeat;
        margin-top: 54px;
        float:right;
        display: inline-block;
    }
    .img5 {
        z-index: 164;
        position: absolute;
        left: 0;
        top: 0;
        width: 124px;
        height: 90px;
    }
    /*操作温度低end*/

    /*无溶剂*/
    .wrap12 {
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
        width:355px;
        height:180px;
        margin:0 auto 10px auto;
    }

    /*无溶剂end*/

    /*能耗低*/
    .box8 {
        height: 260px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.07);
        width: 355px;
        margin:0 auto 10px auto;
    }
    .section38 {
        width: 315px;
        height: 234px;
        margin:0 auto 0;
    }

    .info27 {
        width: 64px;
        height: 26px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 16px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 26px;
        text-align: left;
        padding-top:15px;
    }

    .word70 {
        width: 314px;
        height: 125px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(58, 58, 58, 1);
        font-size: 13px;
        line-height: 20px;
        text-align: left;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .outer15 {
        width: 314px;
        height: 60px;
        border-radius: 5px;
        overflow: hidden;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd646b8319da93546b23c3b0e314c0223044d3b48b1d7cec6c28cf660c71b1a68)
        100% no-repeat;
        margin:12px auto 0 auto;
    }

    .img7 {
        width: 314px;
        height: 60px;
    }
    /*能耗低end*/

    .server-header .rights img{
        width:355px;
        margin:0 auto 0;
    }
    .imgboxs200{
        position: relative;
    }

</style>
