<template>
    <div>
        <template v-if="!wapShow">
            <div>
                <div class="navigation-box">
                    <router-link to="/">首页</router-link><span style="margin: 0 3px">></span>
                    资讯与动态
                </div>
                <div class="message-header"></div>

                <div class="message-content">
                    <div class="left">
                        <div class="left-li" v-for="item in list" @click="to_Detail(item)">
                            <img :src="require('@/assets/image/message/'+item.imgurl)" />
                            <div class="text">
                                <p class="t1">{{item.name}}</p>
                                <p class="t2">{{item.contents}}</p>
                                <p class="t3">{{item.time}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="right">
                        <div class="r1">推荐资讯</div>
                        <div class="swiper-button-prev">
                            <div class="img1"></div>
                        </div>
                        <div class="swiper-button-next">
                            <div class="img1"></div>
                        </div>
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="item in list" @click="to_Detail(item)" style="cursor: pointer">
                                    <img :src="require('@/assets/image/message/'+item.imgurl)" />
                                    <div class="span1"><span>{{item.contents}}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <message-wap></message-wap>
        </template>
    </div>
</template>

<script>
    import Swiper from "swiper";
    import eventBus from "@/assets/js/eventBus";
    import messageWap from "./wap/index"

    export default {
        name: "message",
        components: {messageWap},
        data(){
            return{
                list:[],
                wapShow:false
            }
        },
        mounted() {
            this.list = require('@/assets/js/data.js').default;
            document.getElementsByTagName("title")[0].innerText = '资讯与动态_重庆敏恒科技有限公司';
            this.$nextTick(()=>{
                let swiper1=new Swiper('.swiper-container', {
                    autoplay: {
                        pauseOnMouseEnter: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    loop: true,
                })
            });
            //切换手机端
            if(this.$route.query) this.wapShow = this.$route.query.wapShow;
            eventBus.$on("wapShow",(data)=>{
                this.wapShow= data;
            })
        },
        methods:{
            to_Detail(data){
                this.$router.push({
                    name:"messageDetail",
                    query:{
                        id:data.id
                    }
                })
            }
        }
    }
</script>

<style scoped>

    /*@import "../../assets/css/message.css";*/

    .message-header{
        margin-top: 17px;
        width: 1100px;
        height: 210px;
        background-image: url("../../assets/image/message/1.png");
        background-size: 100% 100%;
    }

    .message-content{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .message-content .left-li{
        width: 752px;
        height: 161px;
        background: #FFFFFF;
        box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        margin-bottom: 20px;
        display: flex;
        cursor: pointer;
    }
    .message-content .left-li img{
        width: 250px;
        height: 161px;
        object-fit: contain;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }
    .message-content .left-li .text{
        width: 502px;
        height: 161px;
        box-sizing: border-box;
        text-align: left;
        padding-left: 25px;
        padding-right: 27px;
    }
    .message-content .left-li .text .t1{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3A3A3A;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 19px 0 0;
    }
    .message-content .left-li .text .t1:hover{
        color: #0079FF;
    }
    .message-content .left-li .text .t2{
        height: 52px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #3A3A3A;
        line-height: 26px;
        margin: 11px 0 0;
        overflow: hidden;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .message-content .left-li .text .t3{
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #9B9DA2;
        margin: 12px 0 0;
    }
    .message-content .right{
        width: 320px;
        text-align: left;
        position: relative;
    }

    .message-content .right .r1{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #363A44;
    }

    .message-content .right .swiper-container{
        width: 320px;
        height: 492px;
        margin-top: 20px;
        border-radius: 10px;
    }
    .message-content .right .swiper-container img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .message-content .right .swiper-container .span1{
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 4;
        display: block;
        height: 82px;
        overflow: hidden;
        left: 0;
        background-image: url("../../assets/image/meng.png");
        background-size: 100% 100%;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        padding: 15px 18px 15px;
        box-sizing: border-box;
    }
    .message-content .right .swiper-container span{
        height: 52px;
        width: 100%;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .message-content .swiper-button-prev{
        left: auto;
        top: 13px;
        right: 32px;
    }
    .message-content .swiper-button-prev .img1{
        width: 20px;
        height: 20px;
        background-image: url("../../assets/image/message/left.png");
        background-size: 100% 100%;
    }
    .message-content .swiper-button-prev .img1:hover{
        background-image: url("../../assets/image/message/right.png");
        background-size: 100% 100%;
        transform:rotate(180deg);
        -ms-transform:rotate(180deg); 	/* IE 9 */
        -moz-transform:rotate(180deg); 	/* Firefox */
        -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
        -o-transform:rotate(180deg); 	/* Opera */
    }
    .message-content .swiper-button-next{
        left: auto;
        top: 13px;
        right: 0px;
    }
    .message-content .swiper-button-next:after,.swiper-button-prev:after{
        display: none;
    }
    .message-content .swiper-button-next .img1{
        width: 20px;
        height: 20px;
        background-image: url("../../assets/image/message/left.png");
        background-size: 100% 100%;
        transform:rotate(180deg);
        -ms-transform:rotate(180deg); 	/* IE 9 */
        -moz-transform:rotate(180deg); 	/* Firefox */
        -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
        -o-transform:rotate(180deg); 	/* Opera */
    }
    .message-content .swiper-button-next .img1:hover{
        background-image: url("../../assets/image/message/right.png");
        background-size: 100% 100%;
        transform:rotate(0deg);
        -ms-transform:rotate(0deg); 	/* IE 9 */
        -moz-transform:rotate(0deg); 	/* Firefox */
        -webkit-transform:rotate(0deg); /* Safari 和 Chrome */
        -o-transform:rotate(0deg); 	/* Opera */
    }


</style>
